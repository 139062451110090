import React, {useState, useEffect} from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import Banner from "../components/secondary/Banner";
import insightsBanner from "../assets/insights-banner.png";
import ArticleFilter from "../components/insights/ArticleFilter";
import ArticlePreview from "../components/insights/ArticlePreview";
import Pagination from "../components/insights/Pagination";
import UserMessage from "../components/UserMessage";

const Insights = () => {
  const supabase = useSupabaseClient();
  const [header, setHeader] = useState('');
  const [blogPosts, setBlogPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage, setPostsPerPage] = useState(6)
  const [activeFilter, setActiveFilter] = useState("all"); 

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getHeader = async () => {
      const { data, error } = await supabase
        .from("headers")
        .select('*')
        .eq('value', 'news');

      if (data !== null) {
        setHeader(data[0].header_text);      } 
    };

    const fetchBlogPosts = async () => {
      const { data, error } = await supabase
        .from("articles")
        .select("*")
        .order("created_at", { ascending: false });

      if (data !== null) {
        setBlogPosts(data);
        setIsLoading(false);
      }
    };
    
    getHeader();
    fetchBlogPosts();

  }, [supabase]);

  const filteredBlogPosts =
  activeFilter === "all"
    ? blogPosts
    : activeFilter === "news"
    ? blogPosts.filter((post) => post.news === true)
    : blogPosts.filter((post) => post.category.toLowerCase() === activeFilter);

// Calculate the total number of posts after filtering
const totalPosts = filteredBlogPosts.length;

// Calculate the index of the last and first post on the current page
const lastPostIndex = currentPage * postsPerPage;
const firstPostIndex = lastPostIndex - postsPerPage;

// Slice the filtered blog posts to display only the current page
const visibleBlogPosts = filteredBlogPosts.slice(firstPostIndex, lastPostIndex);


return (
  <main className={`page-container ${
    inView ? "animate__animated animate__fadeIn" : ""
    }`} ref={ref}>
    <Banner
      backgroundImg={insightsBanner}
      title="News & Insights"
      desc={header}
      scrollId="insights"
    />
    <section id="insights">
      <ArticleFilter
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {isLoading ? ( // Conditionally render based on isLoading
        <UserMessage message="Loading articles..." />
      ) : visibleBlogPosts.length ? (
        visibleBlogPosts.map((blogPost) => (
          <ArticlePreview
            key={blogPost.id}
            id={blogPost.id}
            title={blogPost.title}
            date={blogPost.date}
            category={blogPost.category}
            img={blogPost.img}
            news={blogPost.news}
          />
        ))
      ) : (
        <UserMessage message="Coming soon. Please check back shortly." />
      )}

      <Pagination
        totalPosts={totalPosts}
        postsPerPage={postsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </section>
  </main>
);
};

export default Insights;
