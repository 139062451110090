import React, { useContext, useEffect, useState } from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { AppContext } from "../context/appContext";
import Banner from "../components/secondary/Banner";
import practiceBanner from "../assets/practices-banner2.webp";
import PracticeButtons from "../components/practiceAreas/PracticeButtons";
import Overview from "../components/practiceAreas/Overview";
import PracticeInsights from "../components/practiceAreas/PracticeInsights";
import PracticeTeam from "../components/practiceAreas/PracticeTeam";
import Contact from "../components/Contact"

const Construction = () => {
  const supabase = useSupabaseClient();
  const [header, setHeader] = useState('');

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const { currentPracticePage, setCurrentPracticePage } =
    useContext(AppContext);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  useEffect(() => {
    setCurrentPracticePage("overview");
    localStorage.setItem("page", "construction");

    const getHeader = async () => {
      const { data, error } = await supabase
        .from("headers")
        .select('*')
        .eq('value', 'construction');

      if (data !== null) {
        setHeader(data[0].header_text);      
      } 
    };
    
    getHeader();

  }, [setCurrentPracticePage, supabase]);

  return (
    <main className={`page-container ${
      inView ? "animate__animated animate__fadeIn" : ""
      }`} ref={ref}>
      <Banner
        backgroundImg={practiceBanner}
        title="Construction Disputes"
        desc={header}
        scrollId="practiceone"
      />
      <section id="practiceone">
        <PracticeButtons currentPage={currentPracticePage} />
        {currentPracticePage === "overview" && <Overview practiceArea={1} />}
        {currentPracticePage === "insights" && <PracticeInsights />}
        {currentPracticePage === "team" && <PracticeTeam />}
        {currentPracticePage === "overview" && <Contact />}
      </section>
    </main>
  );
};

export default Construction;
