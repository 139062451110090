import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

interface BannerProps {
  title: string;
  desc: string;
  backgroundImg: string;
  scrollId: string;
}

const Banner: React.FC<BannerProps> = ({
  title,
  desc,
  backgroundImg,
  scrollId,
}) => {
  return (
    <section
      className="secondary-banner"
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <h1 className="secondary-h1">{title}</h1>
      <p className="secondary-p">{desc}</p>
      <ScrollLink
        className="secondary-link"
        to={scrollId}
        smooth={true}
        duration={650}
        offset={0}
      >
        <FontAwesomeIcon
          aria-label="arrow icon"
          icon={faArrowDown}
          className="banner-icon"
        />
      </ScrollLink>
    </section>
  );
};

export default Banner;
