import React from "react";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import placeholderPhoto from '../../assets/sample-article-photo.jpg';

const ArticlePreview = ({id, date, title, category, img, news}) => {

  const navigate = useNavigate();
  const photoUrl = "https://xpxddvjifmlxtrpnuyqc.supabase.co/storage/v1/object/public/images/articles"
  const urlFriendlyName = title.replace(/[^\w\s-]/g, "").replace(/\s+/g, "-");

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: true,
    });
  };

  const handleClick = () => {
    localStorage.setItem("articleId", id);
    navigate(`/post/${urlFriendlyName}`);
    scrollToTop();
  };

  return (
    <div className="article-preview-container" onClick={handleClick}>
      <img
        className="article-preview-img"
        src={!img ? placeholderPhoto : `${photoUrl}/${img}`}
        alt={title}
        onClick={handleClick}
      />
      <div className="article-preview-copy">
        <h2 className="article-preview-h2">{ category } {news === true ? 'NEWS' : ''} | {date }</h2>
        <hr />
        <h1 className="article-preview-h1" onClick={handleClick}>
        {title}
        </h1>
        <p className="article-preview-link" onClick={handleClick}>
          Read More
          <FontAwesomeIcon
            aria-label="arrow icon"
            icon={faArrowRight}
            className="about-icon"
          />
        </p>
      </div>
    </div>
  );
};

export default ArticlePreview;
