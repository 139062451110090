import React, { createContext, useContext, ReactNode } from "react";
import { useSupabaseClient } from "@supabase/auth-helpers-react";

interface AuthContextType {
  // isAuthenticated: boolean;
  // setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return authContext;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const supabase = useSupabaseClient();


  const login = async (email: string, password: string) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
  
      if (error) {
        alert("Cannot login")
        return;
      }
      localStorage.setItem("isAuthenticated", "true");
    } catch (error) {
      alert("Cannot login")
      return;
    }
  }


  const logout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        alert("Cannot login")
        return;
      }
      localStorage.removeItem("isAuthenticated");
    } catch (error) {
      alert("Cannot login")
    }
  };

  return (
    <AuthContext.Provider value={{ login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
