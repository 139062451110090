import React from 'react'
import { useNavigate } from 'react-router-dom';

interface OptionProps {
    name: string;
    desc: string;
    path: string;
  }

const Option : React.FC<OptionProps> = ({ name, desc, path}) => {
    const navigate = useNavigate();

  return (
    <div onClick={()=>navigate(path)} className="option-container">
        <h2 className='option-h1'>{name}</h2>
        <p className='option-p'>{desc}</p>
        <button className='red-button'>EDIT</button>
    </div>
  )
}

export default Option