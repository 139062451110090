import React, { useEffect, useState } from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import UserMessage from "../UserMessage";
import RedButton from "../RedButton";
import NewsArticle from "./NewsArticle";

const News = () => {
  const supabase = useSupabaseClient();
  const [newsArticles, setNewsArticles] = useState([])
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    const fetchArticles = async () => {
      const { data, error } = await supabase
        .from("articles")
        .select("*")
        .order("created_at", { ascending: false })
        .limit(6);

      if (data !== null) {
        setNewsArticles(data);
      }

      if (error) {
        console.log(error);
      }
    };

    fetchArticles();
  }, [supabase]);



  return (
    <article id="news">
      <div className="news-copy">
        <div className="news-header-section">
          <div>
            <h2 className={`small-h2 ${
              inView ? "animate__animated animate__fadeIn" : ""
              }`} style={{visibility: inView ? "visible" : "hidden",
              }} ref={ref}>NEWS AND INSIGHTS</h2>
            <h1 className={`section-h1 ${
              inView ? "animate__animated animate__fadeInDown" : ""
              }`} style={{visibility: inView ? "visible" : "hidden",
              }} ref={ref}>Stay updated</h1>
          </div>
          <RedButton name="SEE ALL" target="/insights" />
        </div>
        <span className={`animated-span ${
          inView ? "animate__animated animate__fadeInUp" : ""
          }`} style={{visibility: inView ? "visible" : "hidden",
          }} ref={ref}>
        <div className={newsArticles.length ? "news-grid" : ""}>
          {newsArticles.length ? (
            newsArticles.map((article) => (
             <NewsArticle key={article.id} id={article.id} title={article.title} date={article.date} category={article.category}/>
            ))
          ) : (
            <UserMessage message="Coming soon. Please check back shortly." />
          )}
        </div>
        </span>
        <RedButton name="SEE ALL" target="/insights" />
      </div>
    </article>
  );
};

export default News;
