import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";



const AboutLink = ({ to, name, className }) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <Link className='about-link' to={to} onClick={scrollToTop}>
      <FontAwesomeIcon
        aria-label="arrow icon"
        icon={faArrowRight}
        className="about-icon"
      />
      {name}
    </Link>
  );
};

export default AboutLink;
