import React from 'react'
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faSliders } from "@fortawesome/free-solid-svg-icons";


const Item = ({name, id, type, onDelete}) => {
const supabase = useSupabaseClient();
const navigate = useNavigate();

const handleEditClick = (id) => {
localStorage.setItem("itemId", id)
{type === "team" ? navigate(`/dashboard/lawyer/${name}`) : navigate("/dashboard/articles/edit")}
}

const deleteItem = async (id) => {
  const confirmDelete = window.confirm("Are you sure you want to delete this?");
  if (!confirmDelete) {
    return;
  }
  if (type === 'team') {
  const { error } = await supabase
  .from('team')
  .delete()
  .eq('id', id);

if (error) {
  console.log('Error occurred deleting user:', error)
  return
}
}

if (type === 'article') {
  const { error } = await supabase
    .from('articles')
    .delete()
    .eq('id', id);

  if (error) {
    console.log('Error occurred deleting article:', error)
    return
  }
}
onDelete();
}

  return (
    <div>
    <div className='admin-item-container'>
        <p className='admin-item-name' onClick={() => handleEditClick(id)}>{name}</p>
        <div>
        <FontAwesomeIcon className="admin-icon" icon={faSliders} title="edit" onClick={() => handleEditClick(id)}/>
        <FontAwesomeIcon className="admin-icon" icon={faTrashCan} title="delete" onClick={() => deleteItem(id)}/>
        </div>
    </div>
    <hr className='admin-hr' />
</div>
  )
}

export default Item