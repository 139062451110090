import React from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faFax, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import contactBackground from "../../assets/contact.png";
import GoogleMap from "./GoogleMap";

const Contact = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <article
      id="contact"
      style={{
        backgroundImage: `url(${contactBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className={`contact-content ${
        inView ? "animate__animated animate__fadeIn" : ""
        }`} style={{visibility: inView ? "visible" : "hidden",
        }} ref={ref}>
        <div className='map-container'>
          <GoogleMap />
        </div>
        <div className="contact-copy">
          <div>
            <h1 className='section-h1'>Contact Us</h1>
            <p className="contact-para">300 - 145 Wellington Street West</p>
            <p className="contact-para">Toronto, Ontario</p>
            <p className="contact-para">M5J 1H8</p>
            <div className="social-links">
              <a href="tel:4163699393" className="contact-link">
                <FontAwesomeIcon
                  aria-label="phone icon"
                  icon={faPhone}
                  title="phone"
                  className="nav-icon"
                />
                416-369-9393
              </a>
              <a href="fax:4163690665" className="contact-link">
                <FontAwesomeIcon
                  aria-label="fax icon"
                  icon={faFax}
                  title="fax"
                  className="nav-icon"
                />
                416-369-0665
              </a>
              <a href="mailto:info@amrlaw.ca" className="contact-link">
                <FontAwesomeIcon
                  aria-label="email icon"
                  icon={faEnvelope}
                  title="email"
                  className="nav-icon"
                />
                info@amrlaw.ca
              </a>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Contact;
