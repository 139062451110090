import React, { useEffect, useState } from "react";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import ArticlePreview from "../insights/ArticlePreview";
import Pagination from "../insights/Pagination";
import UserMessage from "../UserMessage";

const PracticeInsights = () => {
  const supabase = useSupabaseClient();
  const page = localStorage.getItem("page");
  const [isLoading, setIsLoading] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]);

  const [currentPage, setCurrentPage] = useState(1)
  const postsPerPage = 6;


  useEffect(() => {
    const fetchBlogPosts = async () => {
      setIsLoading(true);
      const { data, error } = await supabase
        .from("articles")
        .select("*")
        .order("created_at", { ascending: false })
        .eq("category", page)

      if (data !== null) {
        setBlogPosts(data);
        setIsLoading(false);
      }

      if (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    
    fetchBlogPosts();

  }, [supabase, page]);


  const filteredBlogPosts = blogPosts.slice().sort((a, b) => {
    // Sort by priority (true values come first)
    if (a.priority && !b.priority) {
      return -1; // a comes before b
    } else if (!a.priority && b.priority) {
      return 1; // b comes before a
    } else {
      return 0; // no change in order
    }
  });

const totalPosts = filteredBlogPosts.length;

const lastPostIndex = currentPage * postsPerPage;
const firstPostIndex = lastPostIndex - postsPerPage;
const visibleBlogPosts = filteredBlogPosts.slice(firstPostIndex, lastPostIndex);


  return <section>


{isLoading ? (
        <UserMessage message="Loading articles..." />
      ) : visibleBlogPosts.length === 0 ? (
        <UserMessage message="Coming soon. Please check back shortly." />
      ) : (
        visibleBlogPosts.map((blogPost) => (
          <ArticlePreview key={blogPost.id} id={blogPost.id} title={blogPost.title} date={blogPost.date} category={blogPost.category} img={blogPost.img} news={blogPost.news}/>
        ))
      )}
      <Pagination totalPosts={totalPosts} postsPerPage={postsPerPage} currentPage={currentPage} setCurrentPage={setCurrentPage}/>

  </section>;
};

export default PracticeInsights;
