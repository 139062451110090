import React, {useState, useEffect} from 'react';
import "animate.css";
import { saveAs } from 'file-saver';
import { useInView } from "react-intersection-observer";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMobileScreen } from '@fortawesome/free-solid-svg-icons';
import Assistant from '../components/team/Assistant';
import noPhoto from '../assets/none.jpg'

const Lawyer = () => {
  const supabase = useSupabaseClient();
  const [lawyer, setLawyer] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const photoUrl = "https://xpxddvjifmlxtrpnuyqc.supabase.co/storage/v1/object/public/images/team"

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const id = localStorage.getItem("teamId");
    const getLawyer = async () => {
      const { data, error } = await supabase
        .from("team")
        .select()
        .eq("id", id)
        .single()
      if (data !== null) {
        setLawyer(data);
      } else {
        console.log('Error loading team member', error);
      }
      setIsLoading(false);
    };
    getLawyer();
  }, [supabase]);

  function generateVCard(data) {
    return `BEGIN:VCARD
    VERSION:3.0
    FN:${data.full_name}
    EMAIL:${data.email}
    TITLE:${data.title}
    TEL;TYPE=voice,work:4163699393 Ext.${data.extension}
    ORG:AMR LLP
    END:VCARD`;
    }

    const generateICalendar = (data) => {
      const vCardString = generateVCard(data);
    
      const iCalendarContent = `BEGIN:VCALENDAR
        VERSION:2.0
        BEGIN:VEVENT
        SUMMARY:Meeting with ${data.full_name}
        DESCRIPTION:Discussion with ${data.full_name}
        DTSTART:20230101T120000Z
        DTEND:20230101T130000Z
        ATTACH;FILENAME=contact.vcf:${vCardString}
        END:VEVENT
        END:VCALENDAR`;
    
      return iCalendarContent;
    };


  // function handleDownloadClick() {
  // const vCardString = generateVCard(lawyer);
  // const blob = new Blob([vCardString], { type: "text/vcard" });
  // saveAs(blob, 'contact.vcf');
  // }

  const handleDownloadClick = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  
    if (isIOS) {
      const iCalendarContent = generateICalendar(lawyer);
      const blob = new Blob([iCalendarContent], { type: 'text/calendar;charset=utf-8' });
      saveAs(blob, 'invite.ics');
    } else {
      const vCardString = generateVCard(lawyer);
      const blob = new Blob([vCardString], { type: 'text/vcard;charset=utf-8' });
      saveAs(blob, 'contact.vcf');
    }
  };
  
  return (
    <main id="lawyer" className={`page-container ${
      inView ? "animate__animated animate__fadeIn" : ""
      }`} ref={ref}>
    {isLoading ? (
      <p className='message'>Loading...</p>
    ) : !lawyer || lawyer.length === 0 ? (
      <p className="message">Error. Could not retrieve lawyer.</p>
    ) : (
      <React.Fragment>
        <section className="lawyer-section">
          <div className="lawyer-subsection">
            <img
              src={lawyer.img !== 'none' ? `${photoUrl}/${lawyer.img}` : noPhoto}
              alt={`profile of ${lawyer.full_name}`}
              className="lawyer-img"
            />
            <button className="red-button download-one" onClick={handleDownloadClick}>
              DOWNLOAD VCARD
            </button>
          </div>
          <div className="lawyer-subsection-details">
            <div className="lawyer-text">
              <h1 className="lawyer-h1">{lawyer.full_name}</h1>
              <h3 className="lawyer-title">
                <span className="red-span">{lawyer.title}</span>
              </h3>
              <a href="tel:4163699393" className="lawyer-contact-link">
                <FontAwesomeIcon
                  aria-label="phone icon"
                  icon={faPhone}
                  title="phone"
                  className="nav-icon"
                />
                {`416-369-9393 Ext ${lawyer.extension}`}
              </a>
              {lawyer.mobile &&   <a href="tel:4163699393" className="lawyer-contact-link">
              <FontAwesomeIcon
                aria-label="phone icon"
                icon={faMobileScreen}
                title="phone"
                className="nav-icon"
              />
              {lawyer.mobile}
            </a>}
              <a href={`mailto:${lawyer.email}`} className="lawyer-contact-link">
                <FontAwesomeIcon
                  aria-label="email icon"
                  icon={faEnvelope}
                  title="email"
                  className="nav-icon"
                />
                {lawyer.email}
              </a>
              <div className="lawyer-practice-section">
                <h2 className="lawyer-h2">Areas of Practice</h2>
                <ul className="lawyer-ul">
                  {lawyer.area_details &&
                    lawyer.area_details.map((item) => {
                      return (
                        <li className="lawyer-li" key={item}>
                          {item}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="lawyer-section">
          <div className="lawyer-staff">
            <h2 className="lawyer-h2">Team</h2>
            {(lawyer.staff && lawyer.staff.length > 0) &&
            lawyer.staff.map((staffer) => {
              return <Assistant key={staffer.extension} name={staffer.name} title={staffer.title} ext={staffer.extension} email={staffer.email}/>
            })
            }
          </div>
          <div className="lawyer-bio">
            <hr />
            <p className="lawyer-desc">{lawyer.desc}</p>
            <button className="red-button download-two" onClick={handleDownloadClick}>
            DOWNLOAD VCARD
          </button>
          </div>
          
        </section>
      </React.Fragment>
    )}
  </main>
  );
};

export default Lawyer;
