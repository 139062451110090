import React, {useEffect} from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";

const NotFound: React.FC = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main id='notfound' className={`page-container ${
      inView ? "animate__animated animate__fadeIn" : ""
      }`} ref={ref}>
      <div className="not-found-container">
      <h1 className="not-found-h1">404</h1>
      <p className="not-found-para">
        We apologize for the inconvenience. The page you were looking for may
        have been moved or doesn't exist.</p>
      <p className="not-found-para">
        If you're seeking legal assistance,
        please feel free to contact our team, and we'll be glad to help you with
        your legal needs. In the meantime, you can explore our website's other
        valuable resources or return to the homepage to find the information you
        need.
      </p>
      </div>
    </main>
  );
};

export default NotFound;
