import React, { useState, useEffect } from 'react';
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import Logout from '../../components/dashboard/Logout';

const Headers = () => {
  const supabase = useSupabaseClient();
  const [headers, setHeaders] = useState([]);
  const [headerValues, setHeaderValues] = useState({});

  useEffect(() => {
    const getHeaders = async () => {
      const { data, error } = await supabase.from("headers").select("*");
      if (data !== null) {
        setHeaders(data);
        const initialHeaderValues = {};
        data.forEach((header) => {
          initialHeaderValues[header.name] = header.header_text;
        });
        setHeaderValues(initialHeaderValues);
      }
    };
    getHeaders();
  }, [supabase]);

  const updateHeader = async (name) => {
    // Get the new header text from the state
    const newHeaderText = headerValues[name];
    
    // Update the header text in the database
    const { data, error } = await supabase
      .from("headers")
      .update({ header_text: newHeaderText })
      .eq('name', name)
      .select();

    if (data !== null) {
      alert(`Header for ${name} page has been updated successfully.`);
    } 
    
    if (error) {
      alert(`Error updating header for ${name} page. Please try again.`);
    }
  };

  const handleInputChange = (e, name) => {
    const { value } = e.target;
    setHeaderValues((prevHeaderValues) => ({
      ...prevHeaderValues,
      [name]: value,
    }));
  };

  return (
    <main id='headers-edit'>
      <Logout />
      <h1 className='dashboard-h1'>Update Page Headers</h1>
      <div className='header-container'>
        {(!headers || headers.length === 0) && (
          <p className='user-message'>No page headers to display.</p>
        )}
        {headers && headers.length > 0 && headers.map((header) => (
          <div className='header-item' key={header.name}>
            <h2 className='header-name'>{header.name} Page</h2>
            <input
              className='form-input'
              type='text'
              value={headerValues[header.name] || ''}
              onChange={(e) => handleInputChange(e, header.name)}
            />
            <button
              className='red-button'
              onClick={() => updateHeader(header.name)}
            >
              SAVE
            </button>
          </div>
        ))}
      </div>
    </main>
  );
};

export default Headers;