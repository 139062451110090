import insurancePhoto from "../assets/insurance.webp"
import constructionPhoto from "../assets/construction.webp"
import cyberPhoto from "../assets/cyber.webp"

export const overview = [
  {
    id: 1,
    img: insurancePhoto,
    alt: "city street with lots of traffic",
    desc: "AMR’s insurance lawyers draw on years of trial and appellate experience to deliver results in this highly specialized field. We are frequently engaged in cases where insurance carriers are responding to casualty, property loss, E&O and D&O, product liability, and other issues. We act as defence counsel, provide coverage assessments, and can advise on policy wording by drawing on our decades of experience in and outside of the courtroom. We know when the best resolution can be achieved by settlement or when the only way to resolve the case is a successful outcome at trial.",
  },
  {
    id: 2,
    img: constructionPhoto,
    alt: "aerial view of commercial construction area",
    desc: "Our construction team acts on behalf of contractors and subcontractors, owners and developers, and architecture and engineering firms. We handle all manner of construction disputes, including holdbacks and liens, delay, defects and omissions and other product or contractual disputes. We are strong litigators with a proven history of successful outcomes for our clients. Whether your dispute involves a residential or commercial property, we understand the importance of securing a successful result that provides you with peace of mind.",
  },
  {
    id: 3,
    img: cyberPhoto,
    alt: "glowing green computer hardware",
    desc: "Following a cyber breach, you need a lawyer to act quickly and with confidence to mitigate any pending losses. Our cyber team is ready to assist insurance carriers with coverage and monitoring of cyber claims, while our breach response team can advise on a communication response, notification and your regulatory or contractual obligations. We build strong relationships with vendors so that your claim is handled with utmost care and without delay.",
  },
];
