import React, {useEffect} from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";

const Accessibility = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main id='disclaimer' className={`page-container ${
      inView ? "animate__animated animate__fadeIn" : ""
      }`} ref={ref}>
      <div className="legal-page">
        <h1 className="dashboard-h1">Multi-year Accessibility Plan (AODA) – Ontario</h1>
        <h2 className="access-h2">Intent</h2>
        <p className="about-para">
        This accessibility plan outlines the strategy of AMR LLP to prevent and remove barriers for people with
        disabilities and comply with the requirements of the Integrated Accessibility Standards Regulation
        under the Accessibility for Ontarians with Disabilities Act, 2005.
        </p>
        <h2 className="access-h2">Statement of Commitment</h2>
        <p className="about-para">
        AMR LLP is committed to providing an accessible environment for all clients, employees, job applicants,
        suppliers, and visitors who may enter our premises, access our information, or use our services. As an
        organization, we respect and comply with the requirements of the Accessibility for Ontarians with
        Disabilities Act, 2005, and its associated regulations. We strive to provide an accessible and welcoming
        environment for everyone by identifying and removing barriers in our workplace and ensuring that new
        barriers are not created. The company ensures that persons with disabilities are provided with equal
        opportunities. We are committed to meeting the needs of individuals with disabilities in a timely and
        integrative manner that respects their dignity and independence.
        </p>
        <h2 className="access-h2">Multi-year Accessibility Plan</h2>
        <h3 className="access-h3">Completed Initiatives</h3>
        <h4 className="access-h4">General</h4>
        <p className="about-para">
        The firm’s physical environment is fully accessible both at the street level and within the main office.
        </p>
        <p className="about-para">
        The office environment is free from tripping hazards and hallways are generously spaced to
        accommodate mobility devices.
        </p>
        <p className="about-para">
        Our restroom facilities include accessible washrooms.
        </p>
        <h4 className="access-h4">Information and Communication Standards</h4>
        <p className="about-para">
        The firm’s information and communication is available in various formats for clients, vendors or
        employees, upon request.
        </p>
        <h4 className="access-h4">Employment Standards</h4>
        <p className="about-para">
        AMR is committed to providing employment practices that are accessible, fair and comply with the
        AODA requirements. We are committed to accommodating persons with disabilities during all stages of
        recruitment including job postings, interviewing and hiring.
        </p>
        <p className="about-para">
        All firm policies and emergency procedures are inclusive in content and upon request in an accessible
        format.
        </p>
        <p className="about-para">
        The firm is committed to annual training for all employees. Annual training remains mandatory and
        includes policies and awareness training on subjects which address diversity, equity, inclusion and
        belonging, human rights and Accessibility for Ontarians.
        </p>
        <h4 className="access-h4">Transportation Standards</h4>
        <p className="about-para">
        If an employee is required to travel for work or to work, AMR will provide alternative arrangements upon
        request to accommodate the needs of the individual.
        </p>
        <h4 className="access-h4">Customer Service Standards</h4>
        <p className="about-para">
        The firm is committed to providing all legal services in a format that is accessible, upon request.
        </p>
        <h3 className="access-h3">New and Ongoing Initiatives</h3>
        <p className="about-para">
        The firm is implementing or revising the following initiatives to prevent and remove barriers and comply
with the Integrated Accessibility Standards Regulation:
        </p>
        <h4 className="access-h4">General</h4>
        <p className="about-para">
        The firm will post our Accessibility Commitment at reception and on our public website.
        </p>
        <h4 className="access-h4">Information and Communication Standards</h4>
        <p className="about-para">
        The firm will consult with people with disabilities to confirm communication needs are meeting standards
outlined in the AODA.
        </p>
        <p className="about-para">
        The firm website will include our Statement of Commitment and Multi-Year Plan.
        </p>
        <p className="about-para">
        The firm website will include ALT Text for all key images and all layout and content will be compliant
        with WCAG 2.0 web accessibility standards.
        </p>
        <p className="about-para">
        An Accessible Customer Service Feedback Form will be available and in an accessible format upon
request to evaluate where improvements should be made.
        </p>
        <h4 className="access-h4">Employment Standards</h4>
        <p className="about-para">
        If any employee requires accommodation for a return to work due to disability related absences, the firm
        will make every effort to provide individual plans in collaboration with the employee to ensure needs are
        met.
        </p>
        <p className="about-para">
        The firm will be committed to reviewing our policies and training materials at least annually to ensure
they satisfy the requirements of the commitment and our multi-year plan.
        </p>
        <p className="about-para">
        The firm will remain committed to training employees on serving individuals with disabilities including the
        use of assistive devices, services animals or support persons.
        </p>
        <h4 className="access-h4">Transportation Standards</h4>
        <p className="about-para">
        At this time the firm does not require mandatory transportation for employment however, it is committed
to providing an individualized plan, upon request for any employee that requires accommodation.
        </p>
        <h4 className="access-h4">Design of Public Spaces Standards (Accessibility Standards for the Built Environment)</h4>
        <p className="about-para">
        The firm maintains public spaces and office premises in a building that is accessible. If any future
design changes are scheduled, it is committed to designing a space that is inclusive and accessible for
clients, employees and vendors.
        </p>
        <h4 className="access-h4">Temporary Disruption</h4>
        <p className="about-para">
        If the firm experiences an unplanned disruption to the facilities or services typically provided, a notice
will be posted at all public entrances and reception counters of the firm.
        </p>
        <h4 className="access-h4">Customer Service Standards</h4>
        <p className="about-para">
        The firm will continue to provide goods and services in a format that is adaptable to persons with
        disabilities.
        </p>
        <p className="about-para">
        Any client, employee or vendor who requires assistive devices, service animals or support persons to
        improve or maintain their needs to access our premises, goods and services or communication with our
        firm will be accommodated unless excluded by law. If excluded by law, the firm will provide resources to
        enable the persons with disabilities to access any goods and services offered by the firm.
        </p>
        <p className="about-para">
        If an individual wishes to provide feedback regarding AMR’s initiatives or plan strategies in delivering
        our services to persons with disabilities, please contact management below.
        </p>
        <p className="about-para">
        Additionally, if you have any questions or concerns about this plan or its initiatives, or if you want to
receive a copy of the plan in a different accessible format, please contact:
        </p>
        <p className="about-para">
        <b>Steffanie Hamilton</b><br/>
        Office Manager<br/>
        Email: shamilton@amrlaw.ca<br/>
        Tel: 416-369-9393, ext. 229<br/>
        Fax: 416-369-0665
        </p>
        <p className="about-para">
       OR
        </p>
        <p className="about-para">
        <b>David Visschedyk</b><br/>
        Managing Partner<br/>
        Email: dvisschedyk@amrlaw.ca<br/>
        Tel: 416-369-9393, ext. 291<br/>
        Fax: 416-369-0665
        </p>
      </div>
    </main>
  );
};

export default Accessibility;