import React, {useState, useEffect} from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import placeholderPhoto from '../assets/sample-article-photo.jpg';
import UserMessage from "../components/UserMessage";

const Article = () => {
  const supabase = useSupabaseClient();
  const [article, setArticle] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const photoUrl = "https://xpxddvjifmlxtrpnuyqc.supabase.co/storage/v1/object/public/images/articles"
  const articleId = localStorage.getItem("articleId");

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchBlogPost = async () => {
      const { data, error } = await supabase
        .from("articles")
        .select("*")
        .eq("id", articleId)
        .single();

      if (data !== null) {
        setArticle(data);
      }

      if (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    
    fetchBlogPost();

  }, [supabase, articleId]);

  return (
    <main
      id="article"
      className={`page-container ${
        inView ? "animate__animated animate__fadeIn" : ""
      }`}
      ref={ref}
    >
      {isLoading ? (
        <UserMessage message="Loading article..." />
      ) : article ? (
        <React.Fragment>
          <h2 className="article-h2">
            {article.category} | {article.date}
          </h2>
          <h1 className="article-h1">{article.title}</h1>

          <img
            className="article-img"
            src={!article.img ? placeholderPhoto : `${photoUrl}/${article.img}`}
            alt="blog preview"
          />

          <div
            className="article-para"
            dangerouslySetInnerHTML={{ __html: article.copy }}
          />
          </React.Fragment>
      ) : (
        <UserMessage message="No Article Found" />
      )}
    </main>
  );
};

export default Article;