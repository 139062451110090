import React from 'react'

interface AssistantProps {
  name: string;
  title: string;
  ext: number;
  email: string;
}

const Assistant: React.FC<AssistantProps> = ({name, title, ext, email}) => {
  return (
    <div className='assistant'>
      <p className="staff-text">{`${name}, ${title}`}</p>
      <a href="tel:4163699393" className="staff-text-link">{`416-369-9393 Ext. ${ext}`}</a>
      <a href={`mailto:${email}`} className="staff-text-link">{email}</a>
    </div>
  );
};

export default Assistant