import React, {useState, useEffect} from 'react'
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../components/auth/Authentication';

const Login: React.FC = () => {
  const supabase = useSupabaseClient();
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const navigate = useNavigate();
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async (e:any) => {
    e.preventDefault();
    try {
      await login(email, password);
      navigate("/dashboard");
    } catch (error) {
      console.error("Login error:", error);
      alert('An error occurred during login. Please contact your site administrator.')
    }
  };

  return (
    <main id="login">
        <div className='login-container'>
        <h1>LOGIN</h1>
        <form className='login-form'>
            <input type='email' placeholder='email' required onChange={(e) => setEmail(e.target.value)}/>
            <input type='password' placeholder='password' required onChange={(e) => setPassword(e.target.value)}/>
            <button className='login-button' onClick={handleLogin}>Login</button>
        </form>
        </div>
    </main>
  )
}

export default Login