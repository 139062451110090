import React from "react";
import { overview } from "../../data/overview";

interface OverviewProps {
  practiceArea: number;
}

const Overview: React.FC<OverviewProps> = ({ practiceArea }) => {
  return (
    <section>
      <img className="practice-main-img" src={overview[practiceArea].img} alt={overview[practiceArea].alt} />
      <p className="practice-area-text">{overview[practiceArea].desc}</p>
    </section>
  );
};

export default Overview;
