import React from 'react'
import { animateScroll as scroll } from "react-scroll";


const Pagination = ({totalPosts, postsPerPage, currentPage, setCurrentPage}) => {
    let pages = [];
  
    for (let i = 1; i <= Math.ceil(totalPosts/postsPerPage); i++ ) {
        pages.push(i)
    }

    const scrollToTop = () => {
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
      });
    };

    const handleClick = (page) => {
      setCurrentPage(page)
      scrollToTop();
    }

  return (
    <div className='pagination'>{(pages?.length > 1) && pages.map((page, index) => {
return <button className={`pagination-button ${currentPage === page ? "active" : ""}`} key={index} onClick={() => handleClick(page)}>{page}</button>
    })}</div>
  )
}

export default Pagination;