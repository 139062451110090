import React, { useState, useEffect } from 'react';
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

const SmallNav = () => {
  const supabase = useSupabaseClient();
  const [startTime, setStartTime] = useState('9:00');
  const [endTime, setEndTime] = useState('5:00');

  useEffect(() => {
    const getHours = async () => {
      const { data, error } = await supabase
        .from("hours")
        .select("*")

      if (data !== null) {
        setStartTime(data[0].start_time);
        setEndTime(data[0].end_time);
      } 

      if (error) {
        console.log(error);
      }
    };
    getHours();
  }, [supabase]);

  return (
    <ul className="small-ul">
      <li>
        <FontAwesomeIcon
          aria-label="phone icon"
          icon={faPhone}
          title="phone"
          className="nav-icon"
        />
        <a href="tel:4163699393" className="nav-small-link">
          416-369-9393
        </a>
      </li>
      <li>
        <FontAwesomeIcon
          aria-label="email icon"
          icon={faEnvelope}
          title="email"
          className="nav-icon"
        />
        <a href="mailto: info@amrlaw.ca" className="nav-small-link">
          info@amrlaw.ca
        </a>
      </li>
      <li>
        <FontAwesomeIcon
          aria-label="hours icon"
          icon={faClock}
          title="hours"
          className="nav-icon"
        />
        {`Mon-Fri: ${startTime}-${endTime}`}
      </li>
    </ul>
  );
};

export default SmallNav;
