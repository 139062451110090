import React from "react";

const GoogleMap: React.FC = () => {
  const apiKey: string | undefined = process.env.REACT_APP_GOOGLE_MAPS_KEY;

  return (
    <iframe
      title="Google Map of Store Location"
      src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=AMR+LLP+145+Wellington+Street+West,+Toronto,+ON+M5J+1H8`}
      style={{ border: 0, width: "100%", height: "100%" }}
      allowFullScreen
      loading="lazy"
    />
  );
};

export default GoogleMap;
