import React, { useState, useEffect } from "react";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { v4 as uuidv4 } from "uuid";
import Logout from "../../components/dashboard/Logout";

const EditArt = () => {
  const supabase = useSupabaseClient();
  const [selectedFile, setSelectedFile] = useState(null);
  const [originalImg, setOriginalImg] = useState(null);
  const [art, setArt] = useState({});

  useEffect(() => {
    const articleId = localStorage.getItem("itemId");
    const fetchArticle = async () => {
      const { data, error } = await supabase
        .from("articles")
        .select("*")
        .eq("id", articleId);

      if (data !== null) {
        setArt(data[0]);
        setOriginalImg(data[0].img);
        const articleData = data[0];

      setArt({
        ...articleData,
        news: String(articleData.news),
        priority: String(articleData.priority),
      });
      }

      if (error) {
        alert(
          "An error occurred retrieving the article. Please try again later."
        );
      }
    };

    fetchArticle();
  }, [supabase]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setArt((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setSelectedFile("none");
    } else {
      setSelectedFile(file);
      setArt((prevData) => ({
        ...prevData,
        img: file.name,
      }));
    }
  };

  const updateArticle = async (e) => {
    e.preventDefault();

    const newsValue = art.news === "true";
  const priorityValue = art.priority === "true";

    if (
      art.img &&
      art.img !== originalImg &&
      originalImg !== null
    ) {
      const fileExtension = selectedFile.name.split(".").pop();
      const imgName = `${uuidv4()}.${fileExtension}`;

      setArt((prevData) => ({
        ...prevData,
        img: imgName,
      }));

      const { data, error } = await supabase.storage
        .from("images")
        .upload(`/articles/${imgName}`, selectedFile);

      if (data) {
        art.img = imgName;
      }

      if (error) {
        alert("An error occurred. Please try again later.");
      }
    }

    if (
      art &&
      art.title &&
      art.copy &&
      art.category
    ) {
      if (art.news === false) {
        art.priority = false;
      }

      const { data, error } = await supabase
        .from("articles")
        .update({
          title: art.title,
          img: art.img,
          category: art.category,
          priority: priorityValue,
          copy: art.copy,
          date: art.date,
          news: newsValue,
        })
        .eq("id", art.id)
        .select();

      if (data) {
        alert("Article was updated successfully.");
      }

      if (error) {
        alert("An error occurred. Please try again later.");
      }
    } else {
      alert("Missing required fields.");
    }
  };

  const handleOptionChange = (event) => {
    setArt((prevData) => ({
      ...prevData,
      category: event.target.value,
    }));
  };

  const handleNewsChange = (event) => {
    setArt((prevData) => ({
      ...prevData,
      news: event.target.value,
    }));
  };

  const handlePriorityChange = (event) => {
    setArt((prevData) => ({
      ...prevData,
      priority: event.target.value,
    }));
  };

  const handleCopyChange = (value) => {
    setArt((prevData) => ({
      ...prevData,
      copy: value,
    }));
  };

  return (
    <main id="article-edit">
      <Logout />
      <h1 className="dashboard-h1">Edit Article</h1>
      <div className="form-container">
        <form className="team-form" onSubmit={updateArticle}>
          <h2 className="form-h2">Photo*</h2>
          {art.img && (
            <div className="upload-text">
              <p>
                {selectedFile ? selectedFile.name : art.img} - Current
                Photo
              </p>
            </div>
          )}
          <label htmlFor="photo-upload-input" className="upload-button-label">
            <div className="red-button upload">
              UPLOAD
              <input
                type="file"
                id="photo-upload-input"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </div>
          </label>
          <h2 className="form-h2">Title*</h2>
          <input
            className="form-input"
            type="text"
            value={art.title}
            name="title"
            onChange={handleInputChange}
            required
          />

          <h2 className="form-h2">Category*</h2>

          <div className="custom-select">
            <input
              type="radio"
              id="insurance"
              value="insurance"
              checked={art.category === "insurance"}
              onChange={handleOptionChange}
            />
            <label className="article-label" htmlFor="insurance">
              Insurance
            </label>
          </div>
          <div className="select-container">
            <div className="custom-select">
              <input
                type="radio"
                id="construction"
                value="construction"
                checked={art.category === "construction"}
                onChange={handleOptionChange}
              />
              <label className="article-label" htmlFor="construction">
                Construction
              </label>
            </div>
            <div className="custom-select">
              <input
                type="radio"
                id="cyber"
                value="cyber"
                checked={art.category === "cyber"}
                onChange={handleOptionChange}
              />
              <label className="article-label" htmlFor="cyber">
                Cyber
              </label>
            </div>
            <div className="custom-select">
              <input
                type="radio"
                id="events"
                value="events"
                checked={art.category === "events"}
                onChange={handleOptionChange}
              />
              <label className="article-label" htmlFor="events">
                Events
              </label>
            </div>
          </div>
          <h2 className="form-h2">Is This A News Article*</h2>
          <div className="select-container">
          <div className="custom-select">
          <input
            type="radio"
            id="news-yes"
            value="true"
            name="news"
            onChange={handleNewsChange}
            checked={art.news === "true"} // Use strings for value
          />
          <label className="article-label" htmlFor="news-yes">
            Yes
          </label>
        </div>
        <div className="custom-select">
          <input
            id="news-no"
            type="radio"
            name="news"
            value="false"
            onChange={handleNewsChange}
            checked={art.news === "false"} // Use strings for value
          />
          <label className="article-label" htmlFor="news-no">
            No
          </label>
        </div>
          </div>

          {art.news === 'true' && (
            <div>
              <h2 className="form-h2">Pin Article to Top?*</h2>
              <div className="select-container">
              <div className="custom-select">
              <input
                type="radio"
                id="priority-yes"
                value="true"
                name="priority"
                onChange={handlePriorityChange}
                checked={art.priority === "true"} // Use strings for value
              />
              <label className="article-label" htmlFor="article-news">
                Yes
              </label>
            </div>
            <div className="custom-select">
              <input
                id="priority-no"
                type="radio"
                value="false"
                name="priority"
                onChange={handlePriorityChange}
                checked={art.priority === "false"} // Use strings for value
              />
              <label className="article-label" htmlFor="article-news">
                No
              </label>
            </div>
              </div>
            </div>
          )}

          <h2 className="form-h2">Content</h2>
          <ReactQuill
            theme="snow"
            value={art.copy}
            onChange={handleCopyChange}
          />
          <br />
          <br />
          <button className="red-button" type="submit">
            SAVE ARTICLE
          </button>
        </form>
      </div>
    </main>
  );
};

export default EditArt;
