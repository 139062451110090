import React from "react";

type UserMessageProps = {
  message: string;
};

const UserMessage: React.FC<UserMessageProps> = ({ message }) => {
  return <p className="user-message">{message}</p>;
};

export default UserMessage;
