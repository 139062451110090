import React, { useEffect, useState } from "react";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import LawyerPreview from "../team/LawyerPreview";
import UserMessage from "../UserMessage";

const Team = () => {
  const supabase = useSupabaseClient();
  const [teamMembers, setTeamMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const page = localStorage.getItem("page");

  useEffect(() => {
    const getTeam = async () => {
      const { data, error } = await supabase.from("team").select("*");

      if (data !== null) {
        const filteredTeamMembers = data.filter((lawyer) =>
          lawyer.AOP.includes(page)
        );
        const sortedTeamMembers = filteredTeamMembers.sort((a, b) => {
          // Compare titles first
          const titleOrder = {
            "managing partner": 1,
            "client services partner": 2,
            "partner": 3,
            "associate": 4,
          };
          const titleA = a.title.toLowerCase();
          const titleB = b.title.toLowerCase();
          const titleComparison = titleOrder[titleA] - titleOrder[titleB];
      
          // If titles are the same, compare last names
          if (titleComparison === 0) {
            // Extract last names by taking the last part of the full name string
            const lastNameA = a.full_name.split(' ').pop().toLowerCase();
            const lastNameB = b.full_name.split(' ').pop().toLowerCase();
            return lastNameA.localeCompare(lastNameB);
          }
      
          return titleComparison;
        });
        setTeamMembers(sortedTeamMembers);
      } else {
        console.log("Error loading team members", error);
      }
      setIsLoading(false);
    };
    getTeam();
  }, [supabase, page]);

  return (
    <section
      id="practice-team"
      className={teamMembers.length > 3 ? "team-grid" : "center-team"}
    >
      {isLoading ? (
        <UserMessage message="Loading team members..." />
      ) : teamMembers.length === 0 ? (
        <UserMessage message="no team members to display" />
      ) : (
        teamMembers.map((lawyer) => (
          <LawyerPreview
            key={lawyer.id}
            id={lawyer.id}
            name={lawyer.full_name}
            title={lawyer.title}
            img={lawyer.img}
          />
        ))
      )}
    </section>
  );
};

export default Team;
