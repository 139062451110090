import React, {useState, useEffect} from 'react'
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import Logout from '../../components/dashboard/Logout'

const Hours = () => {
  const supabase = useSupabaseClient();
  const [startTime, setStartTime] = useState('N/A');
  const [endTime, setEndTime] = useState('N/A');

  useEffect(() => {
    const getHours = async () => {
      const { data, error } = await supabase
        .from("hours")
        .select("*")

      if (data !== null) {
        setStartTime(data[0].start_time);
        setEndTime(data[0].end_time);
      } 
    };
    getHours();
  }, [supabase]);

  const updateHours = async (e) => {
    e.preventDefault();
    const trimmedStartTime = startTime.trim();
    const trimmedEndTime = endTime.trim();

    if (trimmedStartTime !== '' && trimmedEndTime !== '' && startTime && endTime ) {
      console.log(startTime, endTime);
      const { data, error } = await supabase
      .from('hours')
      .update({ start_time: startTime, end_time: endTime })
      .eq('name', 'hours')
      .select();

      if (data) {
        alert('Hours updated successfully!');
      }

      if (error) {
        alert('An error occurred while updating hours. Please try again.');
      }

    } else {
      alert('Start and end times cannot be blank');
    }
  }

  return (
    <main id='hours-edit'>
       <Logout />
      <h1 className='dashboard-h1'>Update Office Hours</h1>
      <div className='form-container'>
      <form className='hours-form' onSubmit={updateHours}>
      <label className='form-h2'>Start Time</label>
      <input className='form-input' type='text' value={startTime} onChange={(e) => setStartTime(e.target.value)} required/>
      <label className='form-h2'>End Time</label>
      <input className='form-input' type='text' value={endTime} onChange={(e) => setEndTime(e.target.value)} required/>
      <button className='red-button'>SAVE</button>
      </form>
      </div>
      </main>
  )
}

export default Hours