interface NavigationLink {
  key: number;
  title: string;
  linkType: string;
  url: string;
}

export const navigationLinks: NavigationLink[] = [
  { key: 0, title: "about us", linkType: "hashtag", url: "about" },
  {
    key: 1,
    title: "Insurance",
    linkType: "link",
    url: "/insurance",
  },
  {
    key: 2,
    title: "construction",
    linkType: "link",
    url: "/construction",
  },
  {
    key: 3,
    title: "cyber",
    linkType: "link",
    url: "/cyber",
  },
  {
    key: 4,
    title: "our team",
    linkType: "link",
    url: "/team",
  },
  {
    key: 5,
    title: "news & insights",
    linkType: "link",
    url: "/insights",
  },
  {
    key: 6,
    title: "contact",
    linkType: "hashtag",
    url: "contact",
  },
];
