import React, {useEffect} from "react";
import Hero from "../components/homepage/Hero";
import About from "../components/homepage/About";
import News from "../components/homepage/News";
import Contact from "../components/homepage/Contact";

const Home: React.FC = () => {

  return (
    <main>
      <Hero />
      <About />
      <News />
      <Contact />
    </main>
  );
};

export default Home;
