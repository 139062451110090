import React, { useContext, useEffect } from "react";
import { AppContext } from "../../context/appContext";

const ArticleFilter = ({setActiveFilter, setCurrentPage}) => {
  const { activeArticlesFilter, setActiveArticlesFilter } =
    useContext(AppContext);

    const handleButtonClick = (filter) => {
      setActiveArticlesFilter(filter);
      setActiveFilter(filter);
      setCurrentPage(1);
    };

    useEffect(() => {
      // Reset the activeArticlesFilter to "all" when the component mounts
      setActiveArticlesFilter("all");
      setActiveFilter("all");
      setCurrentPage(1);
    }, [setActiveArticlesFilter, setActiveFilter, setCurrentPage]);

  return (
    <div className="filter-container">
      <span className="filter-span">
        Filter By:
        <button
          className={`filter-button ${
            activeArticlesFilter === "all" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("all")}
        >
          all articles
        </button>
        |
        <button
          className={`filter-button ${
            activeArticlesFilter === "insurance" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("insurance")}
        >
          insurance
        </button>
        |
        <button
          className={`filter-button ${
            activeArticlesFilter === "construction" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("construction")}
        >
          construction
        </button>
        |
        <button
          className={`filter-button ${
            activeArticlesFilter === "cyber" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("cyber")}
        >
         cyber
        </button>
        |
        <button
          className={`filter-button ${
            activeArticlesFilter === "news" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("news")}
        >
          news
        </button>
        |
        <button
          className={`filter-button ${
            activeArticlesFilter === "events" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("events")}
        >
          events
        </button>
      </span>
    </div>
  );
};

export default ArticleFilter;
