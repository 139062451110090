import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/Authentication';

const Logout: React.FC = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = async (e:any) => {
    e.preventDefault();
    try {
      await logout();
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
      alert('An error occurred during logout. Please contact your site administrator.')
    }
  };

  return (
    <div className='logout-container'>
       <FontAwesomeIcon className="admin-icon-home" icon={faHouse} title="go to dashboard" onClick={() => navigate('/dashboard')}/>
      <button className="red-button" onClick={handleLogout}>LOGOUT</button>
    </div>
  );
};

export default Logout;
