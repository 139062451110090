import React, {useEffect} from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";

const Copyright: React.FC = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const year = new Date().getFullYear();
  return (
    <main id='copyright' className={`page-container ${
      inView ? "animate__animated animate__fadeIn" : ""
      }`} ref={ref}>
      <div className="legal-page">
        <h1 className="dashboard-h1">Copyright</h1>
        <p className="about-para">
        Copyright © {year} AMR LLP. All Rights Reserved.
        </p>
        <p className="about-para">
        Reproduction, distribution, display, or transmission of the content of this website is strictly prohibited without the prior written consent of AMR LLP. Unauthorized use of any content from this website may violate copyright, trademark, and other laws. AMR LLP reserves all rights not expressly granted in and to the website and its content. For inquiries or requests regarding the use of content from this website, please contact us directly.
        </p>
      </div>
    </main>
  );
};

export default Copyright;
