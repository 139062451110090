import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";


const NewsArticle = ({ id, date, title, category }) => {

  const navigate = useNavigate();
  const urlFriendlyName = title.replace(/[^\w\s-]/g, "").replace(/\s+/g, "-");


  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleArticleClick = () => {
    localStorage.setItem("articleId", id);
    navigate(`/post/${urlFriendlyName}`)
    scrollToTop();
  }


  return (
    <article onClick={handleArticleClick}>
      <Link className="news-link">
        <p className="news-h2">{date}</p>
        <hr className="news-hr" />
        <div className="news-date-container">
          <FontAwesomeIcon
            aria-label="arrow icon"
            icon={faSquare}
            className="news-icon"
          />
          <h2 className="news-date">{category}</h2>
        </div>
        <h1 className="news-title">
          {title}
        </h1>
      </Link>
    </article>
  );
};

export default NewsArticle;
