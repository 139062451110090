import React from "react";
import { useNavigate } from "react-router-dom";

type RedButtonProps = {
  name: string;
  target: string;
};

const RedButton: React.FC<RedButtonProps> = ({ name, target }) => {
  const navigate = useNavigate();

  const handleClick = () => {
      window.scrollTo(0, 0);
      navigate(target);
    }


  return (
    <button className="red-button" onClick={handleClick}>
      {name}
    </button>
  );
};

export default RedButton;
