import React from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";
import AboutLink from "./AboutLink";
import RedButton from "../RedButton";
import aboutBackground from "../../assets/about-background.jpg";

const About = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <article
      id="about"
      style={{
        backgroundImage: `url(${aboutBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className='about-copy'>
        <section className={`about-left ${
          inView ? "animate__animated animate__fadeIn" : ""
          }`} style={{visibility: inView ? "visible" : "hidden",
          }} ref={ref}>
          <AboutLink to="/insurance" name="Insurance Defence" />
          <hr className="about-hr" />
          <AboutLink to="/construction" name="Construction Disputes"/>
          <hr className="about-hr" />
          <AboutLink to="/cyber" name="Cyber Security" />
          <hr className="about-hr" />
        </section>
        <section className="about-right">
          <h2 className='small-h2'>ABOUT US</h2>
          <h1 className='section-h1'>
            Legal solutions for companies that mean business
          </h1>
          <p className="about-para">
          At Aronovitch Macaulay Rollo LLP, we are more than just litigators. Our firm takes pride in providing our clients with strategic, cost-effective solutions to complex legal challenges and delivering comprehensive legal counsel to businesses of all sizes. Specializing in insurance defence, construction disputes, and cyber security & incident response, our approach sets us apart as a reliable and forward-thinking legal partner.</p>
           
           <p className="about-para">Our dedicated team is ready to help you navigate even the most intricate legal matters. Contact us today to discover how we can provide tailored solutions to protect your interests and achieve your legal objectives.
</p>
          <RedButton name="MEET OUR TEAM" target="/team" />
        </section>
      </div>
    </article>
  );
};

export default About;
