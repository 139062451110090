import React from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";
import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import SmallNav from "../SmallNav";
import heroBackground from "../../assets/hero.png";

const Hero: React.FC = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div>
      <SmallNav />
      <section

      className={`page-container ${
      inView ? "animate__animated animate__fadeIn" : ""
      }`} ref={ref}
        id="hero"
        style={{
          backgroundImage: `url(${heroBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="hero-content">
          <div className="hero-copy">
            <h1 className={`hero-h1 ${inView ? "animate__animated animate__fadeInLeft" : "" }`} ref={ref}  >
              Our reputation <br /> is built on <br />
              <span className="hero-h1-span">results.</span>
            </h1>
            <span className={`fade ${
      inView ? "animate__animated animate__fadeIn" : ""
      }`} style={{visibility: inView ? "visible" : "hidden",
    }} ref={ref}>
            <Link
              className="hero-link"
              to="about"
              smooth={true}
              duration={650}
              offset={0}
            >
              DISCOVER MORE
              <FontAwesomeIcon
                aria-label="arrow icon"
                icon={faArrowRight}
                className="hero-icon"
              />
            </Link>
            </span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
