import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

interface AMRContextState {
  currentPracticeArea: number;
  setCurrentPracticeArea: Dispatch<SetStateAction<number>>;
  currentPracticePage: string;
  setCurrentPracticePage: Dispatch<SetStateAction<string>>;
  activeArticlesFilter: string;
  setActiveArticlesFilter: Dispatch<SetStateAction<string>>;
}

const initialContextState: AMRContextState = {
  currentPracticeArea: 0,
  setCurrentPracticeArea: () => {},
  currentPracticePage: "overview",
  setCurrentPracticePage: () => {},
  activeArticlesFilter: "all",
  setActiveArticlesFilter: () => {},
};

export const AppContext = createContext<AMRContextState>(initialContextState);

export const ContextProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [currentPracticeArea, setCurrentPracticeArea] = useState(0);
  const [currentPracticePage, setCurrentPracticePage] = useState("overview");
  const [activeArticlesFilter, setActiveArticlesFilter] = useState("all");

  const contextValue: AMRContextState = {
    currentPracticeArea,
    setCurrentPracticeArea,
    currentPracticePage,
    setCurrentPracticePage,
    activeArticlesFilter,
    setActiveArticlesFilter,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
