import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import Logout from '../../components/dashboard/Logout'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Item from '../../components/dashboard/Item';

const Team = () => {
  const supabase = useSupabaseClient();
  const [searchTerm, setSearchTerm] = useState('');
  const [teamMembers, setTeamMembers] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const getTeamMembers = async () => {
      const { data, error } = await supabase
        .from("team")
        .select("*")

      if (data !== null) {
        const filteredTeamMembers = data.filter((person) =>
          person.full_name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        const sortedTeamMembers = filteredTeamMembers.sort((a, b) =>
          a.full_name.localeCompare(b.full_name)
        );
      setTeamMembers(sortedTeamMembers);
      } else {
        console.log('Error loading team member', error);
      }
    };
    getTeamMembers();
  }, [supabase, searchTerm]);


  const handleDeleteItem = (id) => {
    setTeamMembers((prevTeamMembers) =>
      prevTeamMembers.filter((member) => member.id !== id)
    );
  };

  return (
    <main id="admin-team">
      <Logout />
      <h1 className='dashboard-h1'>Manage Team Members</h1>
      <button className='red-button-admin' onClick={() => navigate("/dashboard/lawyers/new")}>ADD NEW TEAM MEMBER</button>
      <div className="search-bar">
      <input
        type="text"
        className='search-input'
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <button className='search-button'>
        <FontAwesomeIcon icon={faSearch} />
      </button>
    </div>
    {(!teamMembers || teamMembers.length === 0) && <p className='user-message'>No team members to display</p>}
    {teamMembers && teamMembers.length > 0 && teamMembers.map((person) => {
      return <Item key={person.id} name={person.full_name} type="team" id={person.id} onDelete={() => handleDeleteItem(person.id)}/>
    })}
    </main>
  )
}

export default Team