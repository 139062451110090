import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./components/auth/Authentication";
import { ContextProvider } from "./context/appContext";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Team from "./pages/Team";
import Lawyer from "./pages/Lawyer";
import Insights from "./pages/Insights";
import Article from "./pages/Article";
import Insurance from "./pages/Insurance";
import Construction from "./pages/Construction";
import Cyber from "./pages/Cyber";
import Disclaimer from "./pages/Disclaimer";
import Accessibility from "./pages/Accessibility";
import Copyright from "./pages/Copyright";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import Dashboard from "./pages/adminPanel/Dashboard";
import TeamMembers from "./pages/adminPanel/Team";
import Articles from "./pages/adminPanel/Articles"
import TeamEdit from "./pages/adminPanel/TeamEdit";
import ArticleEdit from "./pages/adminPanel/ArticleEdit";
import Hours from "./pages/adminPanel/Hours";
import Headers from "./pages/adminPanel/Headers";
import EditMember from "./pages/adminPanel/EditMember";
import EditArt from "./pages/adminPanel/EditArt";
import "./App.css";

function App() {
  return (
    <Router>
      <ContextProvider>
        <AuthProvider>
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/team" element={<Team />} />
              <Route path="/lawyer/:name" element={<Lawyer />} />
              <Route path="/insights" element={<Insights />} />
              <Route path="/post/:title" element={<Article />} />
              <Route path="/insurance" element={<Insurance />} />
              <Route path="/construction" element={<Construction />} />
              <Route path="/cyber" element={<Cyber />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
              <Route path="/accessibility" element={<Accessibility />} />
              <Route path="/copyright" element={<Copyright />} />
              <Route path="/admin" element={<Login />} />
              <Route path='/' element={<ProtectedRoute/>}>
              <Route path='/dashboard' element={<Dashboard/>}/>
              <Route path='/dashboard/lawyers' element={<TeamMembers/>}/>
              <Route path='/dashboard/lawyers/new' element={<TeamEdit/>}/>
              <Route path='/dashboard/lawyer/:name' element={<EditMember/>}/>
              <Route path='/dashboard/articles' element={<Articles/>}/>
              <Route path='/dashboard/articles/new' element={<ArticleEdit/>}/>
              <Route path='/dashboard/articles/edit' element={<EditArt />}/>
              <Route path='/dashboard/hours/edit' element={<Hours/>}/>
              <Route path='/dashboard/headers/edit' element={<Headers/>}/>
              </Route>
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </Layout>
        </AuthProvider>
      </ContextProvider>
    </Router>
  );
}

export default App;
