import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import Logout from '../../components/dashboard/Logout'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Item from '../../components/dashboard/Item';

const Articles = () => {
  const supabase = useSupabaseClient();
  const [searchTerm, setSearchTerm] = useState('');
  const [articles, setArticles] = useState([]);
    
    const navigate = useNavigate();

    useEffect(() => {
      const getArticles = async () => {
        const { data, error } = await supabase
          .from("articles")
          .select("*")
  
        if (data !== null) {
          const filteredArticles = data.filter((article) =>
            article.title.toLowerCase().includes(searchTerm.toLowerCase())
          );

        setArticles(filteredArticles);
        } else {
          console.log('Error loading articles', error);
        }
      };
      getArticles();
    }, [supabase, searchTerm]);

    const handleDeleteItem = (id) => {
      setArticles((prevArticles) =>
        prevArticles.filter((article) => article.id !== id)
      );
    };

    return (
      <main id="admin-team">
        <Logout />
        <h1 className='dashboard-h1'>Manage News & Articles</h1>
        <button className='red-button-admin' onClick={() => navigate("/dashboard/articles/new")}>ADD NEW ARTICLE</button>
        <div className="search-bar">
        <input
          type="text"
          className='search-input'
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className='search-button'>
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </div>
      {(!articles || articles.length === 0) && <p className='user-message'>No articles to display</p>}
    {articles && articles.length > 0 && articles.map((article) => {
      return <Item key={article.id} name={article.title} type="article" id={article.id} onDelete={() => handleDeleteItem(article.id)}/>
    })}
      </main>
  )
}

export default Articles