import React, {useState} from 'react'
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import {v4 as uuidv4} from 'uuid';
import Logout from '../../components/dashboard/Logout'

const TeamEdit = () => {
  const supabase = useSupabaseClient();
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [extension, setExtension] = useState('');
  const [desc, setDesc] = useState('');
  const [name, setName] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [addingNewStaff, setAddingNewStaff] = useState(false);
  const [specialtyOptions, setSpecialtyOptions] = useState([]);
  const [specialtyOption, setSpecialtyOption] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const [supportStaff, setSupportStaff] = useState([]);

  const [newStaff, setNewStaff] = useState({
    name: '',
    title: '',
    email: '',
    extension: '',
  });

  const handleOptionChange = (event) => {
    const { value } = event.target;
    setSelectedOption(value);
  };

  const handleAddOption = () => {
    if (selectedOption && !selectedOptions.includes(selectedOption)) {
      setSelectedOptions([...selectedOptions, selectedOption]);
      setSelectedOption('');
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setSelectedFile('none');
    } else {
      setSelectedFile(file);
    }
  };

  const bytesToKB = (bytes) => {
    return (bytes / 1024).toFixed(2);
  };

  const handleRemoveOption = (optionToRemove) => {
    setSelectedOptions(selectedOptions.filter(option => option !== optionToRemove));
  };

  const handleSpecialtyOptionChange = (event) => {
    const { value } = event.target;
    setSpecialtyOption(value);
  };

  const handleAddSpecialtyOption = () => {
    if (specialtyOption && !specialtyOptions.includes(specialtyOption)) {
      setSpecialtyOptions([...specialtyOptions, specialtyOption]);
      setSpecialtyOption('');
    }
  };

  const handleRemoveSpecialtyOption = (optionToRemove) => {
    setSpecialtyOptions(specialtyOptions.filter((option) => option !== optionToRemove));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let imgName = 'none'; 
  
    if (selectedFile && selectedFile !== null) {
      const fileExtension = selectedFile.name.split('.').pop();
      imgName = `${uuidv4()}.${fileExtension}`;
      
      const { data, error } = await supabase.storage
        .from('images')
        .upload(`/team/${imgName}`, selectedFile);

      if (error) {
        alert('An error occurred. Please try again later.');
        imgName = 'none';
      }
     
    }
  
    const { data, error } = await supabase
      .from('team')
      .insert({
        title,
        email,
        extension,
        desc,
        img: imgName,
        full_name: name,
        AOP: selectedOptions,
        area_details: specialtyOptions,
        staff: supportStaff,
      })
      .single();
  
    if (error) {
      alert('Error adding team member. Please try again later.');
    } else {
      alert(`${name} has been added successfully`);
      window.location.reload();
    }
  };



  const handleAddSupportStaff = () => {
    setAddingNewStaff(true);
};

const saveNewStaff = () => {
  if (newStaff.name && newStaff.title && newStaff.email && newStaff.extension) {
    // Add the new support staff to the team member
    const updatedStaff = [ ...supportStaff ];

  // Add the new support staff to the team member's staff array
  updatedStaff.push(newStaff);

  // Update the teamMember state with the new data
  setSupportStaff(updatedStaff);
  

    setNewStaff({
      name: '',
      title: '',
      email: '',
      extension: '',
    })
    setAddingNewStaff(false);
  } else {
    alert('Please fill out all fields for the new support staff member.');
  }
}

const handleRemoveSupportStaff = (extensionToRemove) => {
  setSupportStaff((prevSupportStaff) =>
    prevSupportStaff.filter((staff) => staff.extension !== extensionToRemove)
  );
};


const handleSupportStaffChange = (extensionToUpdate, fieldName, value) => {
  setSupportStaff((prevSupportStaff) =>
    prevSupportStaff.map((staff) => {
      if (staff.extension === extensionToUpdate) {
        return {
          ...staff,
          [fieldName]: value,
        };
      }
      return staff;
    })
  );
};



  return (
    <main id='admin-team-edit'>
       <Logout />
      <h1 className='dashboard-h1'>Add New Team Member</h1>
      <div className='form-container'>
      <form className="team-form" onSubmit={handleSubmit}> 
      <h2 className='form-h2'>Profile Photo</h2>
      {selectedFile && (
        <div className='upload-text'>
          <p>{selectedFile.name} - {bytesToKB(selectedFile.size)} KB</p>
        </div>
      )}
      <label htmlFor="photo-upload-input" className="upload-button-label">
      <div className='red-button upload'>
        UPLOAD
      <input
        type="file"
        id="photo-upload-input"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </div>
    </label>
      <h2 className='form-h2'>Profile Details</h2>
        <input className='form-input' type='text' placeholder='full name*' onChange={(e)=> setName(e.target.value)} required/>
        <input className='form-input' type='text' placeholder='title*' onChange={(e)=> setTitle(e.target.value)} required/>
        <input className='form-input' type='email' placeholder='email*' onChange={(e)=> setEmail(e.target.value)}required/>
        <input className='form-input' type='number' placeholder='phone extension*' onChange={(e)=> setExtension(e.target.value)} required/>
        <textarea className='form-input-desc' type='textarea' placeholder='description*' cols="1" rows="10" wrap="soft" onChange={(e)=> setDesc(e.target.value)} required/>
        <div className='aop-container'>
        <h2 className='form-h2'>Areas of Practice</h2>
        <select
          value={selectedOption}
          onChange={handleOptionChange}
          className='form-input'
        >
          <option value="">select*</option>
          <option value="insurance">Insurance</option>
          <option value="construction">Construction</option>
          <option value="cyber">Cyber</option>
        </select>
        <button type="button" className="red-button add" onClick={handleAddOption}>ADD</button>
      </div>
      <div>
        <ul className='form-ul'>
          {selectedOptions.map(option => (
            <li className='form-option' key={option}>
              {option}
              <button type="button" onClick={() => handleRemoveOption(option)} className='x'>x</button>
            </li>
          ))}
        </ul>
      </div>
      <h2 className='form-h2'>Fields of Expertise</h2>

      <select
      value={specialtyOption}
      onChange={handleSpecialtyOptionChange}
      className='form-input'
    >
    <option value="">select*</option>
    <option value="Accident benefits">Accident benefits</option>
    <option value="Bodily injury (auto and non-auto)">Bodily injury (auto and non-auto)</option>
    <option value="Subrogation">Subrogation</option>
    <option value="Property claims">Property claims</option>
    <option value="Property damage">Property damage</option>
    <option value="Cyber law">Cyber law</option>
    <option value="Slips, trips and falls">Slips, trips and falls</option>
    <option value="Class actions">Class actions</option>
    <option value="Dog bites">Dog bites</option>
    <option value="Contract law">Contract law</option>
    <option value="Human rights claims">Human rights claims</option>
    <option value="Travel/disability">Travel/disability</option>
    <option value="Mass tort litigation">Mass tort litigation</option>
    <option value="Fraud claims">Fraud claims</option>
    <option value="Occupier's liability claims">Occupier's liability claims</option>
    <option value="Disability claims">Disability claims</option>
    <option value="Medical malpractice">Medical malpractice</option>
    <option value="Commercial litigation">Commercial litigation</option>
    <option value="Wrongful dismissal">Wrongful dismissal</option>
    <option value="Employment law">Employment law</option>
    <option value="Appeals">Appeals</option>
    <option value="Construction litigation">Construction litigation</option>
    <option value="Coverage issues">Coverage issues</option>
    <option value="Cyber claims">Cyber claims</option>
    <option value="Cyber incident response">Cyber incident response</option>
    <option value="Insurance defence">Insurance defence</option>
    </select>
    <button
      type="button"
      className="red-button add"
      onClick={handleAddSpecialtyOption}
    >
      ADD
    </button>
    <div>
      <ul className='form-ul'>
        {specialtyOptions.map((option) => (
          <li className='form-option' key={option}>
            {option}
            <button
              type="button"
              onClick={() => handleRemoveSpecialtyOption(option)}
              className='x'
            >
              x
            </button>
          </li>
        ))}
      </ul>
    </div>
    <h2 className='form-h2'>Support Staff</h2>
    
    {supportStaff.map((staff) => (
      <div className="staff-form" key={staff.extension}>
        <input
          name="staff-name"
          type="text"
          className='form-input'
          value={staff.name}
          placeholder='full name*'
          onChange={(e) => handleSupportStaffChange(staff.extension, 'name', e.target.value)}
          required
        />
        <input
          name="staff-title"
          type='text'
          className='form-input'
          value={staff.title}
          placeholder='title*'
          onChange={(e) => handleSupportStaffChange(staff.extension, 'title', e.target.value)}
          required
        />
        <input
          name="staff-email"
          type='email'
          className='form-input'
          value={staff.email}
          placeholder='email*'
          onChange={(e) => handleSupportStaffChange(staff.extension, 'email', e.target.value)}
          required
        />
        <input
          name="staff-extension"
          type='number'
          className='form-input'
          value={staff.extension}
          placeholder='phone extension*'
          onChange={(e) => handleSupportStaffChange(staff.extension, 'extension', e.target.value)}
          required
        />
        <button
          type="button"
          className='remove-button'
          onClick={() => handleRemoveSupportStaff(staff.extension)}
        >
          x remove staff member
        </button>
      </div>
    ))}

    
    {addingNewStaff &&  <div className="staff-form">
    <input
      name="staff-name"
      type="text"
      className='form-input'
      placeholder='full name*'
      value={newStaff.name}
      onChange={(e) => setNewStaff((prevData) => ({ ...prevData, name: e.target.value }))}
      required
    />
    <input
      name="staff-title"
      type='text'
      className='form-input'
      placeholder='title*'
      value={newStaff.title}
      onChange={(e) => setNewStaff((prevData) => ({ ...prevData, title: e.target.value }))}
      required
    />
    <input
      name="staff-email"
      type='email'
      className='form-input'
      placeholder='email*'
      value={newStaff.email}
      onChange={(e) => setNewStaff((prevData) => ({ ...prevData, email: e.target.value }))}
      required
    />
    <input
      name="staff-extension"
      type='number'
      className='form-input'
      placeholder='phone extension*'
      value={newStaff.extension}
      onChange={(e) => setNewStaff((prevData) => ({ ...prevData, extension: e.target.value }))}
      required
    />
    <button
      type="button"
      className='remove-button'
      onClick={() => setAddingNewStaff(false)}
    >
      x remove staff member
    </button>
  </div>}
    <button
      className='red-button staff'
      type="button"
      onClick={addingNewStaff ? saveNewStaff : handleAddSupportStaff}
    >
      {addingNewStaff ? 'SAVE NEW STAFF MEMBER' : 'ADD NEW STAFF MEMBER'}
    </button>



      <button className='red-button' type='submit'>SAVE</button>
      </form>
      </div>
    </main>
  )
}

export default TeamEdit