import React from "react";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';

const Contact = () => {

  const navigate = useNavigate();

  const handleClick = () => {
  
    navigate("/");
  
    setTimeout(() => {
      scroll.scrollToBottom({
        duration: 650,
        smooth: true,
      });
    }, 100); 
  };

  const handleAnchorClick =
  (id) => (e) => {
    e.preventDefault();
    let scrollToElementId = id;
    let scrollToOffset = 0;
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById(scrollToElementId);
      if (element) {
        scrollToOffset = element.offsetTop - 30;
      }
      scroll.scrollTo(scrollToOffset, {
        duration: 500,
        smooth: true,
      });
    }, 120);
  };

  return (
    <section className="contact-secondary">
        <h2 className="contact-secondary-h2">How can we help?</h2>
        <p className="contact-secondary-text">AMR has been proudly serving clients seeking counsel for over 20 years.<span className="break"></span><br />For any additional information, please contact us. We're ready to answer any and all questions.</p>
        <a
        className="red-button"
        href={`#contact`}
        onClick={handleAnchorClick('contact')}
      >
        CONTACT
      </a>
    </section>
  );
};

export default Contact;
