import React, { MouseEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import {
  footerLinksColumn1,
  footerLinksColumn2,
  footerLinksColumn3,
} from "../data/footer";
import logo from "../assets/logo.png";

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const handleAnchorClick =
    (id: string) => (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      let scrollToElementId = id;
      let scrollToOffset = 0;

      navigate("/");
      setTimeout(() => {
        const element = document.getElementById(scrollToElementId);
        if (element) {
          scrollToOffset = element.offsetTop - 30;
        }
        scroll.scrollTo(scrollToOffset, {
          duration: 500,
          smooth: true,
        });
      }, 120);
    };

  const handleNavLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: true,
    });
  };

  const handleLogoClick = () => {
    navigate("/");
    scrollToTop();
  };

  const date = new Date();
  const year = date.getFullYear();

  return (
    <footer>
      <div className="footer-link-container">
        <ul className="footer-ul">
          {footerLinksColumn1.map((link) => (
            <li key={link.key}>
              {link.linkType === "hashtag" ? (
                <a
                  className="footer-a"
                  href={`#${link.url}`}
                  onClick={handleAnchorClick(link.url)}
                >
                  {link.title}
                </a>
              ) : (
                <Link
                  className="footer-link"
                  to={link.url}
                  onClick={handleNavLinkClick}
                >
                  {link.title}
                </Link>
              )}
            </li>
          ))}
        </ul>
        <ul className="footer-ul">
          {footerLinksColumn2.map((link) => (
            <li key={link.key}>
              {link.linkType === "hashtag" ? (
                <a
                  className="footer-a"
                  href={`#${link.url}`}
                  onClick={handleAnchorClick(link.url)}
                >
                  {link.title}
                </a>
              ) : (
                <Link
                  className="footer-link"
                  to={link.url}
                  onClick={handleNavLinkClick}
                >
                  {link.title}
                </Link>
              )}
            </li>
          ))}
        </ul>
        <ul className="footer-ul">
          {footerLinksColumn3.map((link) => (
            <li key={link.key}>
              {link.linkType === "hashtag" ? (
                <a
                  className="footer-a"
                  href={`#${link.url}`}
                  onClick={handleAnchorClick(link.url)}
                >
                  {link.title}
                </a>
              ) : (
                <Link
                  className="footer-link"
                  to={link.url}
                  onClick={handleNavLinkClick}
                >
                  {link.title}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="footer-logo-container">
        <p className="footer-text">{`${year} © AMR LLP. All Rights Reserved.`}</p>
        <img
          src={logo}
          className="footer-logo"
          alt="AMR logo: A stylized arrow made up of a teal, green and red square"
          onClick={handleLogoClick}
        />
      </div>
    </footer>
  );
};

export default Footer;
