import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { navigationLinks } from "../data/navigation";
import { animateScroll as scroll } from "react-scroll";
import logo from "../assets/logo.png";

const Navbar = () => {
  // controls hamburger menu
  const [menuOpen, setMenuOpen] = useState(false);

  // 2 states below needed to set the starting position of the navbar (it changes between homepage and secondary pages)
  const [isHomePage, setIsHomePage] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const hamburgerClick = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 5);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsHomePage(location.pathname === "/");
  }, [location.pathname]);

  const handleLogoClick = () => {
    navigate("/");
    scrollToTop();
  };

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: true,
    });
  };

  const handleAnchorClick =
    (id) => (e) => {
      e.preventDefault();
      let scrollToElementId = id;
      let scrollToOffset = 0;
      navigate("/");
      setMenuOpen(false);
      setTimeout(() => {
        const element = document.getElementById(scrollToElementId);
        if (element) {
          scrollToOffset = element.offsetTop - 30;
        }
        scroll.scrollTo(scrollToOffset, {
          duration: 500,
          smooth: true,
        });
      }, 120);
    };

  const handleNavLinkClick = () => {
    setMenuOpen(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <nav className={`${isScrolled || !isHomePage ? "scrolled" : ""}`}>
      <img
        src={logo}
        className="logo"
        alt="AMR logo: A stylized arrow made up of a teal, green and red square"
        onClick={handleLogoClick}
      />
      <section>
        <ul className={menuOpen ? 'nav-ul-hamburger' : 'nav-ul'}>
          {navigationLinks.map((link) => (
            <li key={link.key}>
              {link.linkType === "hashtag" ? (
                <a
                  className="nav-a"
                  href={`#${link.url}`}
                  onClick={handleAnchorClick(link.url)}
                >
                  {link.title}
                </a>
              ) : (
                <Link
                  className="nav-link"
                  to={link.url}
                  onClick={handleNavLinkClick}
                >
                  {link.title}
                </Link>
              )}
            </li>
          ))}
          <li className="ham-link"><Link
          className="nav-link ham-link"
          to={'/disclaimer'}
          onClick={handleNavLinkClick}
        >
          Disclaimer
        </Link></li>
          <li className="ham-link">
          <Link
                  className="nav-link ham-link"
                  to={'/copyright'}
                  onClick={handleNavLinkClick}
                >
                  Copyright
                </Link></li>
        </ul>
      </section>
      <div className="navbar-toggle" onClick={hamburgerClick}>
    <div className="bar"></div>
    <div className="bar"></div>
    <div className="bar"></div>
  </div>
    </nav>
  );
};

export default Navbar;
