import React, {useEffect} from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";

const Disclaimer = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main id='disclaimer' className={`page-container ${
      inView ? "animate__animated animate__fadeIn" : ""
      }`} ref={ref}>
      <div className="legal-page">
        <h1 className="dashboard-h1">Disclaimer</h1>
        <p className="about-para">
          The information provided on this website is for general informational
          purposes only and should not be construed as legal advice or a legal
          opinion on any specific facts or circumstances. This website and its
          content are not intended to create a lawyer-client relationship
          between you and AMR LLP.
        </p>

        <p className="about-para">
          While we strive to provide accurate and up-to-date information, we
          make no warranties or representations, expressed or implied, regarding
          the completeness, accuracy, reliability, suitability, or availability
          of the information contained on this website or any linked websites.
          Any reliance you place on such information is strictly at your own
          risk.
        </p>

        <p className="about-para">
          The use of this website or communication with AMR LLP through
          this website does not establish a lawyer-client relationship.
          Confidential or time-sensitive information should not be sent through
          email addresses provided on this website.
        </p>

        <p className="about-para">
          Please consult with a qualified legal professional for advice regarding your
          individual situation. Contacting AMR LLP or any of its
          lawyers through this website does not create a lawyer-client
          relationship, and any information you send will not be treated as
          confidential unless we have formally agreed to represent you.
        </p>
      </div>
    </main>
  );
};

export default Disclaimer;
