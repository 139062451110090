import React, { useContext } from "react";
import { AppContext } from "../../context/appContext";

interface PracticeButtonProps {
  currentPage: string;
}

const PracticeButtons: React.FC<PracticeButtonProps> = ({ currentPage }) => {
  const { setCurrentPracticePage } = useContext(AppContext);

  const handleButtonClick = (currentPage: string) => {
    setCurrentPracticePage(currentPage);
  };

  return (
    <div className="practice-buttons">
      <button
        className={currentPage === "overview" ? "red-button-active" : "red-button"}
        onClick={() => handleButtonClick("overview")}
      >
        Overview
      </button>
      <button className={currentPage === "team" ? "red-button-active" : "red-button"} onClick={() => handleButtonClick("team")}>
        Team
      </button>
      <button
        className={currentPage === "insights" ? "red-button-active" : "red-button"}
        onClick={() => handleButtonClick("insights")}
      >
        Insights
      </button>
    </div>
  );
};

export default PracticeButtons;
