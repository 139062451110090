import React, { useState, useEffect } from 'react';
import {v4 as uuidv4} from 'uuid';
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import Logout from '../../components/dashboard/Logout';

const EditMember = () => {
  const supabase = useSupabaseClient();
  const [selectedFile, setSelectedFile] = useState(null);
  const [originalImg, setOriginalImg] = useState(null);
  const [selectedSpecialtyOption, setSelectedSpecialtyOption] = useState('');
  const [selectedAOPOption, setSelectedAOPOption] = useState('');
  const [addingNewStaff, setAddingNewStaff] = useState(false);
  const [teamMember, setTeamMember] = useState({
    id: '',
    created_at: '',
    title: '',
    email: '',
    extension: '',
    mobile: null,
    desc: '',
    img:  '',
    full_name: '',
    AOP: [],
    area_details: [],
    staff: [],
  });

  const [newStaff, setNewStaff] = useState({
    name: '',
    title: '',
    email: '',
    extension: '',
  });

  useEffect(() => {
    const memberId = localStorage.getItem("itemId");
    // Fetch team member data from Supabase here and set it to the state
    const fetchTeamMember = async () => {
      const { data, error } = await supabase
        .from("team")
        .select("*")
        .eq("id", memberId);

      if (data !== null) {
        setTeamMember(data[0]);
        setOriginalImg(data[0].img);
      } 

      if (error) {
        alert('An error occurred retrieving the team member. Please try again later.')
      }
    };

    fetchTeamMember();
  }, [supabase]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTeamMember((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSpecialtyOptionChange = (e) => {
    setSelectedSpecialtyOption(e.target.value);
  };

  const handleAddSpecialtyOption = () => {
    if (selectedSpecialtyOption && !teamMember.area_details.includes(selectedSpecialtyOption)) {
      // Add the selected option to the area_details array
      setTeamMember((prevData) => ({
        ...prevData,
        area_details: [...prevData.area_details, selectedSpecialtyOption],
      }));
      // Clear the selected option
      setSelectedSpecialtyOption('');
    }
  };

  const handleRemoveSpecialtyOption = (optionToRemove) => {
    // Create a new array excluding the option to be removed
    const updatedAreaDetails = teamMember.area_details.filter((option) => option !== optionToRemove);

    // Update the state to reflect the changes
    setTeamMember((prevData) => ({
      ...prevData,
      area_details: updatedAreaDetails,
    }));
  };

  const handleAOPOptionChange = (e) => {
    setSelectedAOPOption(e.target.value);
  };

  const handleAddAOPOption = () => {
    if (selectedAOPOption && !teamMember.AOP.includes(selectedAOPOption)) {
      // Add the selected option to the AOP array
      setTeamMember((prevData) => ({
        ...prevData,
        AOP: [...prevData.AOP, selectedAOPOption],
      }));
      // Clear the selected option
      setSelectedAOPOption('');
    }
  };

  const handleRemoveAOPOption = (optionToRemove) => {
    // Create a new array excluding the option to be removed
    const updatedAOP = teamMember.AOP.filter((option) => option !== optionToRemove);

    // Update the state to reflect the changes
    setTeamMember((prevData) => ({
      ...prevData,
      AOP: updatedAOP,
    }));
  };

  const handleFileChange = (event, ) => {
    const file = event.target.files[0];
    if (!file) {
      setSelectedFile('none');
    } else {
      setSelectedFile(file);
      setTeamMember((prevData) => ({
        ...prevData,
        img: file.name,
      }));
    }
  };

const handleAddSupportStaff = () => {
      setAddingNewStaff(true);
};

  const saveNewStaff = () => {
    if (newStaff.name && newStaff.title && newStaff.email && newStaff.extension) {
      // Add the new support staff to the team member
      const updatedTeamMember = { ...teamMember };

    // Check if staff is null or undefined and initialize it as an empty array
    updatedTeamMember.staff = updatedTeamMember.staff || [];

    // Add the new support staff to the team member's staff array
    updatedTeamMember.staff.push(newStaff);

    // Update the teamMember state with the new data
    setTeamMember(updatedTeamMember);
      setNewStaff({
        name: '',
        title: '',
        email: '',
        extension: '',
      })
      setAddingNewStaff(false);
    } else {
      alert('Please fill out all fields for the new support staff member.');
    }
  }

  const handleRemoveSupportStaff = (index) => {
    const updatedSupportStaff = [...teamMember.staff];
    updatedSupportStaff.splice(index, 1);
    setTeamMember((prevData) => ({
      ...prevData,
      staff: updatedSupportStaff,
    }));
  };

  const handleSupportStaffChange = (index, fieldName, value) => {
    const updatedSupportStaff = [...teamMember.staff];
    updatedSupportStaff[index][fieldName] = value;
    setTeamMember((prevData) => ({
      ...prevData,
      staff: updatedSupportStaff,
    }));
  };


  const updateTeamMember = async (e) => {
    e.preventDefault();

    if (!teamMember.img) {
      setTeamMember((prevData) => ({
        ...prevData,
        img: 'none',
      }));

    } else if (teamMember.img !== 'none' && teamMember.img && teamMember.img !== originalImg && originalImg !== null) {
      const fileExtension = selectedFile.name.split('.').pop();
      const imgName = `${uuidv4()}.${fileExtension}`;
      
      setTeamMember((prevData) => ({
        ...prevData,
        img: imgName,
      }));

      const { data, error } = await supabase.storage
        .from('images')
        .upload(`/team/${imgName}`, selectedFile);

      if (data) {
        teamMember.img = imgName;
      }

      if (error) {
        alert('An error occurred. Please try again later.');
        teamMember.img = 'none';
      }
    }

    if (teamMember && teamMember.full_name && teamMember.title && teamMember.email && teamMember.extension && teamMember.desc) {

      const { data, error } = await supabase
      .from('team')
      .update({ title: teamMember.title,
      email: teamMember.email,
      extension: teamMember.extension,
      mobile: null,
      desc: teamMember.desc,
      img: teamMember.img,
      full_name: teamMember.full_name,
      AOP: teamMember.AOP,
      area_details: teamMember.area_details,
      staff: teamMember.staff
 })
      .eq('id', teamMember.id)
      .select();

      if (data) {
        alert(`${teamMember.full_name} updated successfully`);
      }

      if (error) {
        alert('An error occurred. Please try again later.');
      }

    } else {
      alert('Missing required fields.')
    }
  }


  return (
    <main id='admin-edit'>
      <Logout />
      <h1 className='dashboard-h1'>Edit Team Member</h1>
      <div className='form-container'>
        <form className="team-form" onSubmit={updateTeamMember}>
        <h2 className='form-h2'>Profile Photo</h2>
        {teamMember.img && (
          <div className='upload-text'>
            <p>{selectedFile ? selectedFile.name : teamMember.img} - Current Photo</p>
          </div>
        )}
        <label htmlFor="photo-upload-input" className="upload-button-label">
          <div className='red-button upload'>
            UPLOAD
            <input
              type="file"
              id="photo-upload-input"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </div>
        </label>
      <h2 className='form-h2'>Profile Details</h2>
          <input
            className='form-input'
            type='text'
            name='full_name'
            placeholder='full name*'
            value={teamMember.full_name}
            onChange={handleInputChange}
            required
          />
          <input
            className='form-input'
            type='text'
            name='title'
            placeholder='title*'
            value={teamMember.title}
            onChange={handleInputChange}
            required
          />
          <input
            className='form-input'
            type='email'
            name='email'
            placeholder='email*'
            value={teamMember.email}
            onChange={handleInputChange}
            required
          />
          <input
            className='form-input'
            type='number'
            name='extension'
            placeholder='phone extension*'
            value={teamMember.extension}
            onChange={handleInputChange}
            required
          />
          <textarea
            className='form-input-desc'
            name='desc'
            placeholder='description*'
            cols="1"
            rows="10"
            wrap="soft"
            value={teamMember.desc}
            onChange={handleInputChange}
            required
          ></textarea>
          {/* Dropdown for Areas of Practice */}
          <div className='aop-container'>
            <h2 className='form-h2'>Areas of Practice</h2>
            <select
            name='selectedAOPOption'
            value={selectedAOPOption}
            className='form-input'
            onChange={handleAOPOptionChange}
          >
              <option value="">select*</option>
              <option value="insurance">Insurance</option>
              <option value="construction">Construction</option>
              <option value="cyber">Cyber</option>
            </select>
            <button type="button" className="red-button add" onClick={handleAddAOPOption}>ADD</button>
          </div>
          {/* List of selected Areas of Practice */}
          <div>
            <ul className='form-ul'>
              {teamMember.AOP &&
                teamMember.AOP.map(option => (
                  <li className='form-option' key={option}>
                    {option}
                    <button type="button" className='x' onClick={() => handleRemoveAOPOption(option)}>x</button>
                  </li>
                ))
              }
            </ul>
          </div>
          {/* Dropdown for Fields of Expertise */}
          <h2 className='form-h2'>Fields of Expertise</h2>
          <select
          name='specialtyOption'
          value={selectedSpecialtyOption}
          className='form-input'
          onChange={handleSpecialtyOptionChange}
        >
          <option value="">select*</option>
          <option value="Accident benefits">Accident benefits</option>
          <option value="Bodily injury (auto and non-auto)">Bodily injury (auto and non-auto)</option>
          <option value="Subrogation">Subrogation</option>
          <option value="Property claims">Property claims</option>
          <option value="Property damage">Property damage</option>
          <option value="Cyber law">Cyber law</option>
          <option value="Slips, trips and falls">Slips, trips and falls</option>
          <option value="Class actions">Class actions</option>
          <option value="Dog bites">Dog bites</option>
          <option value="Contract law">Contract law</option>
          <option value="Human rights claims">Human rights claims</option>
          <option value="Travel/disability">Travel/disability</option>
          <option value="Mass tort litigation">Mass tort litigation</option>
          <option value="Fraud claims">Fraud claims</option>
          <option value="Occupier's liability claims">Occupier's liability claims</option>
          <option value="Disability claims">Disability claims</option>
          <option value="Medical malpractice">Medical malpractice</option>
          <option value="Commercial litigation">Commercial litigation</option>
          <option value="Wrongful dismissal">Wrongful dismissal</option>
          <option value="Employment law">Employment law</option>
          <option value="Appeals">Appeals</option>
          <option value="Construction litigation">Construction litigation</option>
          <option value="Coverage issues">Coverage issues</option>
          <option value="Cyber claims">Cyber claims</option>
          <option value="Cyber incident response">Cyber incident response</option>
          <option value="Insurance defence">Insurance defence</option>
          </select>
          <button type="button" className="red-button add" onClick={handleAddSpecialtyOption}>ADD</button>
          {/* List of selected Fields of Expertise */}
          <div>
            <ul className='form-ul'>
              {teamMember.area_details &&
                teamMember.area_details.map(option => (
                  <li className='form-option' key={option}>
                    {option}
                    <button type="button" className='x' onClick={() => handleRemoveSpecialtyOption(option)}>x</button>
                  </li>
                ))
              }
            </ul>
          </div>
          {/* Support Staff */}
          <h2 className='form-h2'>Staff</h2>
          {teamMember.staff &&
            teamMember.staff.map((staffer, index) => (
              <div className="staff-form" key={index}>
                <input
                  name="staff-name"
                  type="text"
                  className='form-input'
                  placeholder='full name*'
                  value={staffer.name}
                  onChange={(e) => handleSupportStaffChange(index, 'name', e.target.value)}
                  required
                />
                <input
                  name="staff-title"
                  type='text'
                  className='form-input'
                  placeholder='title*'
                  value={staffer.title}
                  onChange={(e) => handleSupportStaffChange(index, 'title', e.target.value)}
                  required
                />
                <input
                  name="staff-email"
                  type='email'
                  className='form-input'
                  placeholder='email*'
                  value={staffer.email}
                  onChange={(e) => handleSupportStaffChange(index, 'email', e.target.value)}
                  required
                />
                <input
                  name="staff-extension"
                  type='number'
                  className='form-input'
                  placeholder='phone extension*'
                  value={staffer.extension}
                  onChange={(e) => handleSupportStaffChange(index, 'extension', e.target.value)}
                  required
                />
                <button
                  type="button"
                  className='remove-button'
                  onClick={() => handleRemoveSupportStaff(index)}
                >
                  x remove staff member
                </button>
              </div>
            ))
          }
          {addingNewStaff &&  <div className="staff-form">
          <input
            name="staff-name"
            type="text"
            className='form-input'
            placeholder='full name*'
            value={newStaff.name}
            onChange={(e) => setNewStaff((prevData) => ({ ...prevData, name: e.target.value }))}
            required
          />
          <input
            name="staff-title"
            type='text'
            className='form-input'
            placeholder='title*'
            value={newStaff.title}
            onChange={(e) => setNewStaff((prevData) => ({ ...prevData, title: e.target.value }))}
            required
          />
          <input
            name="staff-email"
            type='email'
            className='form-input'
            placeholder='email*'
            value={newStaff.email}
            onChange={(e) => setNewStaff((prevData) => ({ ...prevData, email: e.target.value }))}
            required
          />
          <input
            name="staff-extension"
            type='number'
            className='form-input'
            placeholder='phone extension*'
            value={newStaff.extension}
            onChange={(e) => setNewStaff((prevData) => ({ ...prevData, extension: e.target.value }))}
            required
          />
          <button
            type="button"
            className='remove-button'
            onClick={() => setAddingNewStaff(false)}
          >
            x remove staff member
          </button>
        </div>}
          <button
            className='red-button staff'
            type="button"
            onClick={addingNewStaff ? saveNewStaff : handleAddSupportStaff}
          >
            {addingNewStaff ? 'SAVE NEW STAFF MEMBER' : 'ADD NEW STAFF MEMBER'}
          </button>
          <button className='red-button' type='submit'>SAVE</button>
        </form>
      </div>
    </main>
  );
};

export default EditMember;