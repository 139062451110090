import React, { useEffect, useState } from "react";
import "animate.css";
import { useInView } from "react-intersection-observer";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import Banner from "../components/secondary/Banner";
import teamsBanner from "../assets/teams-banner.png";
import LawyerPreview from "../components/team/LawyerPreview";
import UserMessage from "../components/UserMessage";

const Team = () => {
  const supabase = useSupabaseClient();
  const [teamMembers, setTeamMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [header, setHeader] = useState('');

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {

    const getHeader = async () => {
      const { data, error } = await supabase
        .from("headers")
        .select('*')
        .eq('value', 'team');

      if (data !== null) {
        setHeader(data[0].header_text);      } 
    };
    
    getHeader();

    const getTeam = async () => {
      const { data, error } = await supabase
        .from("team")
        .select("*");
        if (data !== null) {
          // Sort the team members first by title and then by last name
          const sortedTeamMembers = data.sort((a, b) => {
            // Compare titles first
            const titleOrder = {
              "managing partner": 1,
              "client services partner": 2,
              "partner": 3,
              "associate": 4,
            };
            const titleA = a.title.toLowerCase();
            const titleB = b.title.toLowerCase();
            const titleComparison = titleOrder[titleA] - titleOrder[titleB];
        
            // If titles are the same, compare last names
            if (titleComparison === 0) {
              // Extract last names by taking the last part of the full name string
              const lastNameA = a.full_name.split(' ').pop().toLowerCase();
              const lastNameB = b.full_name.split(' ').pop().toLowerCase();
              return lastNameA.localeCompare(lastNameB);
            }
        
            return titleComparison;
          });
        setTeamMembers(sortedTeamMembers);
      } else {
        console.log('Error loading team members', error);
      }
      setIsLoading(false);
    };
    getTeam();
  }, [supabase]);

  return (
    <main className={`page-container ${
      inView ? "animate__animated animate__fadeIn" : ""
      }`} ref={ref}>
    <Banner
      backgroundImg={teamsBanner}
      title="Our Team"
      desc={header}
      scrollId="team"
    />
    <section id="team" className={teamMembers.length ? "team-grid" : ""}>
      {isLoading ? (
        <UserMessage message="Loading team members..." />
      ) : teamMembers.length === 0 ? (
        <UserMessage message="no team members to display" />
      ) : (
        teamMembers.map((lawyer) => (
          <LawyerPreview
            key={lawyer.id}
            id={lawyer.id}
            name={lawyer.full_name}
            title={lawyer.title}
            img={lawyer.img}
          />
        ))
      )}
    </section>
  </main>
  );
};

export default Team;
