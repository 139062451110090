import React from "react";
import { useNavigate } from "react-router-dom";
import noPhoto from "../../assets/none.jpg";

interface LawyerPreviewProps {
  id: string;
  img: string;
  name: string;
  title: string;
}

const LawyerPreview: React.FC<LawyerPreviewProps> = ({id, img, name, title}) => {

  const navigate = useNavigate();    
  const photoUrl = "https://xpxddvjifmlxtrpnuyqc.supabase.co/storage/v1/object/public/images/team"
  const urlFriendlyName = name.replace(/ /g, "-");


  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleLawyerClick = () => {
    localStorage.setItem("teamId", id);
    navigate(`/lawyer/${urlFriendlyName}`)
    scrollToTop();
  }
    return (
      <div className="lawyer-preview-container" onClick={handleLawyerClick}>
        <img
          className="lawyer-preview-img"
          src={!img || img === 'none' ? noPhoto : `${photoUrl}/${img}`}
          alt={`profile of ${name}`}
        />
        <h2 className="lawyer-preview-h2">{name}</h2>
        <p className="lawyer-preview-p">{title}</p>
      </div>
    );
  };

export default LawyerPreview;
