import React, {useState} from 'react'
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {v4 as uuidv4} from 'uuid';
import Logout from '../../components/dashboard/Logout'

const ArticleEdit = () => {
  const supabase = useSupabaseClient();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [date, setDate] = useState(''); // [1
  const [news, setNews] = useState(false);
  const [title, setTitle] = useState('');
  const [priority, setPriority] = useState(false);
  const [value, setValue] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setSelectedFile('none');
    } else {
      setSelectedFile(file);
    }
  };

  function formatDate(date) {
    const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const bytesToKB = (bytes) => {
    return (bytes / 1024).toFixed(2);
  };

  const handleSubmitArticle = async (event) => {
    event.preventDefault();
    let imgName;

    if (!selectedFile || selectedFile === null) {
      alert('Please upload an article image.');
      return;
    }
  
    if (selectedFile && selectedFile !== null) {
      const fileExtension = selectedFile.name.split('.').pop();
      imgName = `${uuidv4()}.${fileExtension}`;
      
      const { data, error } = await supabase.storage
        .from('images')
        .upload(`/articles/${imgName}`, selectedFile);

        setSelectedFile(imgName);

        if (data) {
          setSelectedFile(imgName);
        }

      if (error) {
        alert('An error occurred. Please try again later.');
        setSelectedFile(null);
        console.log(error);
      }
    }

    const currentDate = new Date();
    const articleDate = formatDate(currentDate);

    if (news === false) {
      setPriority(false);
  }

    const { data, error } = await supabase
      .from('articles')
      .insert({
        title,
        img: imgName || selectedFile,
        category: selectedOption,
        priority,
        copy: value,
        date: articleDate,
        news
      })
      .single();
  
    if (error) {
      alert('Error adding article. Please try again later.');
      console.log(error);
    } else {
      alert('Article has been added successfully.');
      window.location.reload();
    }
  };

  return (
    <main id='admin-article-edit'>
    <Logout />
   <h1 className='dashboard-h1'>Add New Article</h1>
   <div className='form-container'>
   <form className="team-form" onSubmit={handleSubmitArticle}> 
   <h2 className='form-h2'>Photo*</h2>
   {selectedFile && (
     <div className='upload-text'>
       <p>{selectedFile.name} - {bytesToKB(selectedFile.size)} KB</p>
     </div>
   )}
   <label htmlFor="photo-upload-input" className="upload-button-label">
   <div className='red-button upload'>
     UPLOAD
   <input
     type="file"
     id="photo-upload-input"
     onChange={handleFileChange}
     style={{ display: 'none' }}
   />
 </div>
 </label>
   <h2 className='form-h2'>Title*</h2>
     <input className='form-input' type='text' onChange={(e) => setTitle(e.target.value)} required/>
    
     <h2 className='form-h2'>Category*</h2>
  
     <div className="custom-select">
     <input
       type="radio"
       id="insurance"
       value="insurance"
       checked={selectedOption === 'insurance'}
       onChange={handleOptionChange}
     />
     <label className='article-label' htmlFor="insurance">Insurance</label>
</div>
<div className='select-container'>
<div className="custom-select">
     <input
       type="radio"
       id="construction"
       value="construction"
       checked={selectedOption === 'construction'}
       onChange={handleOptionChange}
     />
     <label className='article-label' htmlFor="construction">Construction</label>
     </div>
     <div className="custom-select">
     <input
       type="radio"
       id="cyber"
       value="cyber"
       checked={selectedOption === 'cyber'}
       onChange={handleOptionChange}
     />
     <label className='article-label' htmlFor="cyber">Cyber</label>
     </div>
     <div className="custom-select">
     <input
       type="radio"
       id="events"
       value="events"
       checked={selectedOption === 'events'}
       onChange={handleOptionChange}
     />
     <label className='article-label' htmlFor="events">Events</label>
      </div>
      </div>
      <h2 className='form-h2'>Is This A News Article*</h2>
      <div className='select-container'>
      <div className="custom-select">
      <input
        type="radio"
        id="news-yes"
        value={news}
        name="yesNoOption"
        onChange={(e) => setNews(true)}
      />
      <label className='article-label' htmlFor="article-news">Yes</label>
       </div>
       <div className="custom-select">
       <input
       id="news-no"
       type="radio"
       value={news}
       name="yesNoOption"
       onChange={(e) => setNews(false)}
       />
       <label className='article-label' htmlFor="article-news">No</label>
        </div>
        </div>

{news && <h2 className='form-h2'>Pin Article to Top?*</h2>}
{news && 
      <div className='select-container'>
      <div className="custom-select">
      <input
        type="radio"
        id="priority-yes"
        value={priority}
        name="yesNoPin"
        onChange={(e) => setPriority(true)}
      />
      <label className='article-label' htmlFor="article-news">Yes</label>
       </div>
       <div className="custom-select">
       <input
       id="priority-no"
       type="radio"
       value={priority}
       name="yesNoPin"
       onChange={(e) => setPriority(false)}
       />
       <label className='article-label' htmlFor="article-news">No</label>
        </div>
        </div>

}


   <h2 className='form-h2'>Content</h2>
   <ReactQuill theme="snow" value={value} onChange={setValue} />
   <br />
   <br />
   <button className='red-button' type='submit'>SAVE ARTICLE</button>
   </form>
   </div>
 </main>
)
}

export default ArticleEdit;