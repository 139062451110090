import React from 'react'
import Logout from '../../components/dashboard/Logout';
import Option from '../../components/dashboard/Option';
import { useNavigate } from 'react-router-dom';

const Dashboard: React.FC = () => {
const navigate = useNavigate();
  return (
    <main id="dashboard">
      <Logout />
      <h1 className='dashboard-h1'>Welcome to the Dashboard</h1>
      <div className='dash-container'>
        <Option name="Manage Team" desc="Create, update, and delete team members for your website." path="/dashboard/lawyers"/>
        <Option name="Manage Articles" desc="Create, update, and delete articles for your website." path="/dashboard/articles"/>
      </div>
      <div className='option-2-container' onClick={() => navigate('/dashboard/hours/edit')}><h1 className='option-h1'>Update Office Hours</h1></div>
      <div className='option-2-container' onClick={() => navigate('/dashboard/headers/edit')}><h1 className='option-h1'>Update Page Headers</h1></div>
  
    </main>
  )
}

export default Dashboard